import React from 'react';
import styles from './ScrollUp.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';

const ScrollUp = () => {
  const scrolltop = () => {
    window.scroll({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className={styles.ScrollUp} data-testid='ScrollUp'>
      <FontAwesomeIcon
        data-testid='ScrollUpIcon'
        size='3x'
        className={styles.icon}
        icon={faAngleUp}
        onClick={scrolltop}
      />
    </div>
  );
};

export default ScrollUp;
