import React from 'react';
import styles from './PFSFooter.module.css';

import footerWave from './FooterWave.svg';

import PFSFooterNav from '../PFSFooterNav/PFSFooterNav'
import PFSFooterBranding from '../PFSFooterBranding/PFSFooterBranding';

const PFSFooter = () => (
  <div className={styles.PFSFooter} data-testid="PFSFooter">
    <div id={styles.FooterWaveContainer}>
      <img alt='Footer background' id={styles.FooterWave} src={footerWave}></img>
    </div>
    <PFSFooterNav />
    <PFSFooterBranding />
  </div>
);

PFSFooter.propTypes = {};

PFSFooter.defaultProps = {};

export default PFSFooter;
