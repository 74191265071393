const data = [
  {
    title: 'August 10',
    rows: [
      [
        'Employers',
        'File Form 941 for the second quarter. This due date applies only if you deposited the tax for the quarter in full and on time.',
      ],
      [
        'Employees who work for tips',
        'If you received $20 or more in tips during July, report them to your employer. You can use Form 4070 Employee’s Report of Tips to Employer.',
      ],
    ],
  },
  {
    title: 'August 15',
    rows: [
      [
        'Employers',
        'Nonpayroll Withholding. If the monthly deposit rule applies, deposit the tax for payments in July.',
      ],
      [
        'Employers',
        'Social Security, Medicare, and withheld income tax. If the monthly deposit rule applies, deposit the tax for payments in July.',
      ],
    ],
  },
  {
    title: 'September 10',
    rows: [
      [
        'Employees who work for tips',
        'If you received $20 or more in tips during August, report them to your employer. You can use Form 4070 Employee’s Report of Tips to Employer.',
      ],
    ],
  },
  {
    title: 'September 15',
    rows: [
      [
        'Individuals',
        'Make a payment of your current year estimated tax if you are not paying your income tax for the year through withholding (or will not pay in enough tax that way). Use Form 1040-ES. This is the third installment date for estimated tax in the current year.',
      ],
      [
        'Partnerships',
        'File Form 1065. This due date applies only if you were given an additional 5-month extension. Provide each partner with a copy of Schedule K-1 (Form 1065) or a substitute K-1',
      ],
      [
        'C-Corporations',
        'With an extension, the deadline for filing is October 15. File Form 1120 or 1120-A and pay any tax due. This due date applies only if you timely requested an automatic 6-month extension. Otherwise, see April 15.',
      ],
      [
        'S Corporations ',
        'File Form 1120S and pay any tax due. This due date applies only if you timely requested an automatic 6-month extension. Otherwise, see March 15. Provide each shareholder with a copy of Schedule K-1 (Form 1120S) or a substitute Schedule K-1.',
      ],
      [
        'Corporations',
        'Deposit the third installment of your estimated income tax. A worksheet, Form 1120-W, is available to help you make an estimate of your tax for the year.',
      ],
      [
        'Employers',
        'Nonpayroll Withholding. If the monthly deposit rule applies, deposit the tax for payments in August.',
      ],
      [
        'Employers',
        'Social Security, Medicare, and withheld income tax. If the monthly deposit rule applies, deposit the tax for payments in August.',
      ],
    ],
  },
  {
    title: 'October 10',
    rows: [
      [
        'Employees who work for tips',
        'If you received $20 or more in tips during September, report them to your employer. You can use Form 4070 Employee’s Report of Tips to Employer.',
      ],
    ],
  },
  {
    title: 'October 15',
    rows: [
      [
        'Individuals',
        'If you have an automatic 6-month extension to file your income tax return, file Form 1040, 1040A, or 1040EZ and pay any tax, interest, and penalties due.',
      ],
      [
        'Electing large partnerships',
        'File Form 1065-B. This due date applies only if you were given an additional 6-month extension. See March 15 for the due date for furnishing the Schedules K-1 to the partners.',
      ],
      [
        'Employers',
        'Nonpayroll Withholding. If the monthly deposit rule applies, deposit the tax for payments in September.',
      ],
      [
        'Employers',
        'Social Security, Medicare, and withheld income tax. If the monthly deposit rule applies, deposit the tax for payments in September.',
      ],
    ],
  },
  {
    title: 'October 31',
    rows: [
      [
        'Employers',
        ' Social Security, Medicare, and withheld income tax. File form 941 for the third quarter of the current year. Deposit any undeposited tax. (If your tax liability is less than $2,500, you can pay it in full with a timely filed return.) If you deposited the tax for the quarter in full and on time, you have until November 10 to file the return.',
      ],
      [
        'Employers',
        'Federal Unemployment Tax. Deposit the tax owed through September if more than $500.',
      ],
      [
        'Employers',
        'Income Tax Withholding. Ask employees whose withholding allowances will be different in the next calendar year to fill out a new Form W-4.',
      ],
    ],
  },
  {
    title: 'November 10',
    rows: [
      [
        'Employers',
        'This due date applies only if you deposited the tax for the quarter in full and on time.',
      ],
      [
        'Employees who work for tips ',
        'If you received $20 or more in tips during October, report them to your employer. You can use Form 4070 Employee’s Report of Tips to Employer.',
      ],
    ],
  },
  {
    title: 'November 15',
    rows: [
      [
        'Employers',
        'Nonpayroll Withholding. If the monthly deposit rule applies, deposit the tax for payments in October.',
      ],
      [
        'Employers',
        'Social Security, Medicare, and withheld income tax. If the monthly deposit rule applies, deposit the tax for payments in October.',
      ],
    ],
  },
  {
    title: 'December 10',
    rows: [
      [
        'Employees who work for tips',
        'If you received $20 or more in tips during November, report them to your employer. You can use Form 4070 Employee’s Report of Tips to Employer.',
      ],
    ],
  },
  {
    title: 'December 15',
    rows: [
      [
        'Corporations',
        'Deposit the fourth installment of your estimated income tax. A worksheet, Form 1120-W, is available to help you estimate your tax for the year.',
      ],
      [
        'Employers',
        'Social Security, Medicare, and withheld income tax – If the monthly deposit rule applies, deposit the tax for payments in November.',
      ],
      [
        'Employers',
        'Nonpayroll Withholding. If the monthly deposit rule applies, deposit the tax for payments in November.',
      ],
    ],
  },
  {
    title: 'January 10',
    rows: [
      [
        'Employees who work for tips',
        'If you received $20 or more in tips during December, report them to your employer. You can use Form 4070 Employee’s Report of Tips to Employer.',
      ],
    ],
  },
  {
    title: 'January 15',
    rows: [
      [
        'Employers',
        'Social Security, Medicare and withheld income tax. If the monthly deposit rule applies, deposit the tax for payments in December of this year.',
      ],
      [
        'Employers',
        'Nonpayroll Withholding. If the monthly deposit rule applies, deposit the tax for payments in December.',
      ],
      [
        'Individuals',
        'Make a payment of your estimated tax for this year if you did not pay your income tax for the year through withholding (or did not pay in enough tax that way). Use Form 1040-ES. This is the final installment date for this year’s estimated tax. However, you do not have to make this payment if you file this year’s return (Form 1040) and pay any tax due by January 31 of the following year.',
      ],
      [
        'Farmers and Fisherman',
        'Pay your estimated tax for this year using Form 1040ES. You have until April 15 to file this year’s income tax return (Form 1040). If you don’t pay your estimated tax by January 15, you must file this year’s return and pay any tax due by March 1 to avoid an estimated tax penalty.',
      ],
    ],
  },
  {
    title: 'January 31',
    rows: [
      [
        'Employers',
        'Federal unemployment tax. File Form 940 (or 940-EZ) for this year. If your undeposited tax is $500 or less, you can either pay it with your return or deposit it. If it is more than $500, you must deposit it. However, if you already deposited the tax for the year in full and on time, you have until February 10 to file the return.',
      ],
      [
        'Employers',
        'Social Security, Medicare, and withheld income tax. File Form 941 for the fourth quarter of this year. Deposit any undeposited tax. (If your tax liability is less than $2,500, you can pay it in full with a timely filed return.) If you deposited the tax for the quarter in full and on time, you have until February 10 to file the return.',
      ],
      [
        'Employers',
        'Nonpayroll taxes. File Form 945 to report income tax withheld for this year on all nonpayroll items, including backup withholding and withholding on pensions, annuities, IRAs, gambling winnings, and payments of Indian gaming profits to tribal members. Deposit any undeposited tax. (If your tax liability is less than $2,500, you can pay it in full with a timely filed return.) If you deposited the tax for the year in full and on time, you have until February 10 to file the return.',
      ],
      [
        'Employers',
        'Give your employees their copies of Form W-2 for the previous year. If an employee agreed to receive Form W-2 electronically, post it on a website accessible to the employee and notify the employee of the posting by January 31st.',
      ],
      [
        'Employers',
        'Give annual information Forms 1098, 1099 and W-2G to recipients for certain payments made during the year.',
      ],
      [
        'Employers',
        'Give your employees Forms 1095-B and 1095-C for health care coverage.',
      ],
      [
        'Individuals',
        'File your income tax return (Form 1040) for this year if you did not pay your last installment of estimated tax by January 15. Filing your return and paying any tax due by January 31 prevents any penalty for late payment of last installment.',
      ],
    ],
  },
  {
    title: 'February 10',
    rows: [
      [
        'Employees who work for tips',
        'If you received $20 or more in tips during January, report them to your employer. You can use Form 4070 Employee’s Report of Tips to Employer.',
      ],
    ],
  },
  {
    title: 'February 15',
    rows: [
      [
        'Individuals',
        'If you claimed exemption from income tax withholding last year on the Form W-4 you gave your employer, you must file a new Form W-4 by this date to continue your exemption for another year.',
      ],
      [
        'Employers',
        'Nonpayroll Withholding. If the monthly deposit rule applies, deposit the tax for payments in January.',
      ],
      [
        'Employers',
        'Furnish Forms 1099-B, 1099-S and certain Forms 1099-MISC to recipients.',
      ],
    ],
  },
  {
    title: 'February 28',
    rows: [
      [
        'All Businesses',
        'File information returns (Form 1099) for certain payments you made during previous year. These payments are described under January 31. There are different forms for different types of payments. Use a separate Form 1096 to summarize and transmit the forms for each type of payment. See the General Instructions for Forms 1099, 1098, 5498, and W-2G for information on what payments are covered, how much the payment must be before a return is required, which form to use, and extensions of time to file. If you file Forms 1098, 1099, or W-2G electronically (not by magnetic media), your due date for filing them with the IRS will be extended to March 31. The due date for giving the recipient these forms remains January 31.',
      ],
      [
        'Employers',
        'File Form W-3 with Copy A of all Forms W-2 issued for the current tax year.',
      ],
      [
        'Employers',
        'File Forms 1094-B, 1095-B, 1094-C, and 1095-C if filing on paper',
      ],
    ],
  },
  {
    title: 'March 1',
    rows: [
      [
        'Farmers and fishermen',
        'File Form 1040 and pay any tax due. However, you have until April 15 to file if you paid your previous year estimated tax by January 15 of the current year.',
      ],
    ],
  },
  {
    title: 'March 10',
    rows: [
      [
        'Employees who work for tips',
        'If you received $20 or more in tips during February, report them to your employer. You can use Form 4070 Employee’s Report of Tips to Employer.',
      ],
    ],
  },
  {
    title: 'March 15',
    rows: [
      [
        'Employers',
        'Nonpayroll Withholding. If the monthly deposit rule applies, deposit the tax for payments in February.',
      ],
      [
        'Employers',
        'Social Security, Medicare, and withheld income tax. If the monthly deposit rule applies, deposit the tax for payments in February.',
      ],
      [
        'Corporations',
        'C corporation must file Form 1120 by April 15 without an extension. File Form 1120 or 1120-A and pay any tax due. If you want an automatic 6-month extension of time to file the return, file Form 7004 and deposit what you estimate you owe.',
      ],
      [
        'S Corporations',
        'File Form 1120S and pay any tax due. Provide each shareholder with a copy of Schedule K-1 (Form 1120S), Shareholder’s Share of Income, Credits, Deductions, etc., or a substitute Schedule K-1. If you want an automatic 6-month extension of time to file the return, file Form 7004 and deposit what you estimate you owe.',
      ],
      [
        'S Corporation election',
        'File Form 2553, Election by a Small Business Corporation, to choose to be treated as an S Corporation beginning with current calendar year. If Form 2553 is filed late, S treatment will begin with next calendar year.',
      ],
      [
        'Electing large partnerships',
        'Provide each partner with a copy of Schedule K-1 (Form 1065-B), Partner’s Share of Income (Loss) From an Electing Large Partnership. This due date is effective for the first March 15 following the close of the partnership’s tax year. The due date of March 15 applies even if the partnership requests an extension of time to file the Form 1065-B by filing Form 8736 or Form 8800.',
      ],
    ],
  },
  {
    title: 'March 31',
    rows: [
      [
        'Electronic filing of Forms',
        'Electronic filing of Forms 1097, 1098, 1099, 3921, 3922, 1094-B, 1095-B, 1094-C, 1095-C, and W-2G File Forms 1097, 1098, 1099, 3921, 3922, 1094-B, 1095-B, 1094-C, 1095-C, or W-2G with the IRS. This due date applies only if you file electronically (not by magnetic media). Otherwise, see February 28. The due date for giving the recipient these forms will still be January 31. For information about filing Forms 1097, 1098, 1099, 3921, 3922, or W-2G electronically, see Publication 1220, Specifications for Filing Forms 1097, 1098, 1099, 3921, 3922, 5498 and W-2G Magnetically or Electronically.',
      ],
    ],
  },
  {
    title: 'April 10',
    rows: [
      [
        'Employees who work for tips',
        'If you received $20 or more in tips during March, report them to your employer. You can use Form 4070 Employee’s Report of Tips to Employer.',
      ],
    ],
  },
  {
    title: 'April 15',
    rows: [
      [
        'Individuals',
        'File an income tax return (Form 1040, 1040A, or 1040EZ) and pay any tax due. If you want an automatic 6-month extension of time to file the return, file Form 4868, Application for Automatic Extension of Time To File U.S. Individual Income Tax Return, or you can get an extension by phone if you pay part or all of your estimate of income tax due with a credit card. Then file Form 1040, 1040A, or 1040EZ by October 15.',
      ],
      [
        'Household employers',
        'If you paid cash wages of $1,900 or more in the previous year to a household employee, file Schedule H (Form 1040) with your income tax return and report any employment taxes. Report any federal unemployment (FUTA) tax on Schedule H if you paid total cash wages of $1,000 or more in any calendar quarter of the previous 2 years to household employees. Also report any income tax you withheld for your household employees.',
      ],
      [
        'Individuals',
        'If you are not paying your current year income tax through withholding (or will not pay in enough tax during the year that way), pay the first installment of your current estimated tax. Use Form 1040-ES.',
      ],
      [
        'Employers',
        'Social Security, Medicare and withheld income tax. If the monthly deposit rule applies, deposit the tax payments for March.',
      ],
      [
        'Employers',
        'Nonpayroll Withholding. If the monthly deposit rule applies, deposit the tax for payments in March.',
      ],
      [
        'Partnerships',
        'File a previous calendar year return (Form 1065). Provide each partner with a copy of Schedule K-1 (Form 1065), Partner’s Share of Income, Deductions, Credits, etc., or a substitute Schedule K-1. If you want an automatic 5-month extension to file the return and provide Schedule K-1 or a substitute Schedule K-1, file Form 7004. Then, file Form 1065 by September 15.',
      ],
      [
        'Electing large partnerships',
        'File a previous calendar year return (Form 1065-B). If you want an automatic 6-month extension of time to file the return, file Form 7004. Then file Form 1065-B by October 16. See March 15 for the due date for furnishing the Schedules K-1 to the partners.',
      ],
      [
        'Corporations',
        'Deposit the first installment of your estimated income tax for current year. A worksheet, Form 1120-W, is available to help you estimate your tax for the year.',
      ],
    ],
  },
  {
    title: 'April 30',
    rows: [
      [
        'Employers',
        'Social Security, Medicare, and withheld income tax. File form 941 for the first quarter of current year. Deposit any undeposited tax. (If your tax liability is less than $2,500, you can pay it in full with a timely filed return.) If you deposited the tax for the quarter in full and on time, you have until May 10 to file the return.',
      ],
      [
        'Employers',
        'Federal Unemployment Tax. Deposit the tax owed through March if more than $500.',
      ],
    ],
  },
  {
    title: 'May 10',
    rows: [
      [
        'Employers',
        'File Form 941 for the first quarter. This due date applies only if you deposited the tax for the quarter in full and on time.',
      ],
      [
        'Employees who work for tips',
        'If you received $20 or more in tips during April, report them to your employer. You can use Form 4070 Employee’s Report of Tips to Employer.',
      ],
    ],
  },
  {
    title: 'May 15',
    rows: [
      [
        'Employers',
        'Nonpayroll Withholding. If the monthly deposit rule applies, deposit the tax for payments in April.',
      ],
      [
        'Employers',
        'Social Security, Medicare, and withheld income tax. If the monthly deposit rule applies, deposit the tax for payments in April.',
      ],
      [
        'Tax Exempt Entities',
        'IRS Form 990 is due by the 15th day of the 5th month after the accounting period ends. This deadline also applies to other 990 variants such as 990-N, 990-EZ, and 990-PF.',
      ],
    ],
  },
  {
    title: 'June 10',
    rows: [
      [
        'Employees who work for tips',
        'If you received $20 or more in tips during May, report them to your employer. You can use Form 4070 Employee’s Report of Tips to Employer.',
      ],
    ],
  },
  {
    title: 'June 15',
    rows: [
      [
        'Individuals',
        'If you are a U.S. citizen or resident alien living and working (or on military duty) outside the United States and Puerto Rico, file Form 1040 and pay any tax, interest, and penalties due. Otherwise, see April 15. If you want additional time to file your return, file Form 4868 to obtain 4 additional months to file. Then file Form 1040 by October 15. However, if you are a participant in a combat zone you may be able to further extend the filing deadline.',
      ],
      [
        'Individuals',
        'Make a payment of your current estimated tax if you are not paying your income tax for the year through withholding (or will not pay in enough tax that way). Use Form 1040-ES. This is the second installment date for estimated tax in current year.',
      ],
      [
        'Corporations',
        'Deposit the second installment of your estimated income tax. A worksheet, Form 1120-W, is available to help you estimate your tax for the year.',
      ],
      [
        'Employers',
        'Nonpayroll Withholding. If the monthly deposit rule applies, deposit the tax for payments in May.',
      ],
      [
        'Employers',
        'Social Security, Medicare, and withheld income tax. If the monthly deposit rule applies, deposit the tax for payments in May.',
      ],
    ],
  },
  {
    title: 'June 30',
    rows: [
      [
        'FBAR Form',
        'File FBAR Form 114 electronically with the Financial Crimes Enforcement Network (FinCEN).',
      ],
    ],
  },
  {
    title: 'July 10',
    rows: [
      [
        'Employees who work for tips',
        'If you received $20 or more in tips during June, report them to your employer. You can use Form 4070 Employee’s Report of Tips to Employer.',
      ],
    ],
  },
  {
    title: 'July 15',
    rows: [
      [
        'Employers',
        'Nonpayroll Withholding. If the monthly deposit rule applies, deposit the tax for payments in June.',
      ],
      [
        'Employers',
        'Social Security, Medicare, and withheld income tax. If the monthly deposit rule applies, deposit the tax for payments in June.',
      ],
    ],
  },
  {
    title: 'July 31',
    rows: [
      [
        'Employers',
        'Social Security, Medicare, and withheld income tax. File form 941 for the second quarter of the current year. Deposit any undeposited tax. (If your tax liability is less than $2,500, you can pay it in full with a timely filed return.) If you have deposited the tax for the quarter in full and on time, you have until August 10 to file the return.',
      ],
      [
        'Employers',
        'Federal Unemployment Tax. Deposit the tax owed through June if more than $500.',
      ],
      [
        'Employers',
        'If you maintain an employee benefit plan, such as a pension, profit sharing, or stock bonus plan, file form 5500 or 5500-EZ for previous calendar year. If you use a fiscal year as your plan year, file the form by the last day of the seventh month after the plan year ends.',
      ],
    ],
  },
];
export default data;
