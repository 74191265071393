import React from 'react';
import styles from './ContentContainer.module.css';

const ContentContainer = (props) => (
  <div className={styles.ContentContainer} data-testid="ContentContainer">
    { props.children }
  </div>
);

ContentContainer.propTypes = {};

ContentContainer.defaultProps = {};

export default ContentContainer;
