import { faAward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import BaseReason from './BaseReason';

const ReasonQuality = () => {
    return (
        <BaseReason
            icon={<FontAwesomeIcon icon={faAward} size='4x' />}
            title='Quality'
            content={[
                "At PFS, we are dedicated to providing high-quality service to our clients.",
                "We are customer-focused and responsive, ensuring that we are always available to answer your questions and address your concerns."
            ]}
        />
    )
}

export default ReasonQuality