import React from 'react';
import PropTypes from 'prop-types';
import styles from './ArticleBanner.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import emptyImage from './empty.png';
import imageLoading from './loading.gif';
import LoadingImage from '../LoadingImage/LoadingImage';

const ArticleBanner = (props) => {
  const { image, title, publishedAt } = props;

  return (
    <div className={styles.ArticleBanner} data-testid='ArticleBanner'>
      <LoadingImage
        image={image}
        styleclass={styles.BannerImage}
        placeholderImage={emptyImage}
        loadingImage={imageLoading}
        altText='ARTICLE'
      />
      <div className={styles.textcentered}>
        <p className={styles.ArticleTitle} data-testid='ArticleBannerTitle'>
          {title}
        </p>

        <div className={styles.Info}>
          <div data-testid='ArticleBannerPublishedDate'>
            <FontAwesomeIcon className={styles.icon} icon={faCalendarDays} />
            {publishedAt ? new Date(publishedAt).toUTCString().slice(0, -13) : '-'}
          </div>
        </div>
      </div>
    </div>
  );
};

ArticleBanner.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  publishedAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]).isRequired,
};

ArticleBanner.defaultProps = {
  image: undefined,
  title: undefined,
  publishedAt: undefined,
};

export default ArticleBanner;
