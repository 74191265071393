import axios from 'axios';
import retryQuery from './RetryQuery';
import { loadFromCache, storeInCache } from '../helpers/CacheHelpers';

const sortByDescendingPriority = (a, b) => {
  const priorityA = a.priority || 0;
  const priorityB = b.priority || 0;
  return priorityB - priorityA;
};

const handleSuccess = (response, key) => {
  const data = response.data.sort(sortByDescendingPriority);
  storeInCache(key, data);
  return data;
};

const handleError = (e) => {
  if (e.response?.status === 503 || e.status === 503) throw e;
  return [];
};

export const getStaffData = async (department) => {
  const key = `staff/${department ?? ''}`;
  const cachedData = loadFromCache(key);
  if (cachedData) return cachedData;
  localStorage.removeItem(key);

  try {
    const response = await retryQuery(() =>
      axios.get(`${process.env.REACT_APP_API_URL}/staff/${department ?? ''}`)
    );
    if (response.status === 200) return handleSuccess(response, key);
    return [];
  } catch (e) {
    return handleError(e);
  }
};
