import React, { useState } from 'react';
import PropTypes from 'prop-types';
import loadingGif from './loading.gif';

const LoadingImage = (props) => {
  const {
    image,
    styleclass,
    altText,
    titleText,
    onClickFunc,
    loadingImage,
    placeholderImage
  } = props;
  const [imageLoading, setImageLoading] = useState(true);

  return (
    <>
      <img
        data-testid='ImageLoading'
        className={styleclass}
        src={loadingImage}
        hidden={!imageLoading}
        alt='Loading...'
      />
      <img
        data-testid='ImageRendered'
        className={styleclass}
        onLoad={() => {
          setImageLoading(false);
        }}
        hidden={imageLoading}
        src={image || placeholderImage}
        alt={altText}
        title={titleText}
        onClick={onClickFunc}
      />
    </>
  );
};

LoadingImage.propTypes = {
  loadingImage: PropTypes.string,
  placeholderImage: PropTypes.string,
  image: PropTypes.string,
  styleclass: PropTypes.string,
  altText: PropTypes.string.isRequired,
  titleText: PropTypes.string,
  onClickFunc: PropTypes.func
};

LoadingImage.defaultProps = {
  loadingImage: loadingGif,
  placeholderImage: undefined,
  image: undefined,
  styleclass: '',
  altText: undefined,
  titleText: '',
  onClickFunc: () => {},
};

export default LoadingImage;
