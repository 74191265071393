import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import BaseReason from './BaseReason';

const ReasonCulture = () => {
    return (
        <BaseReason
            icon={<FontAwesomeIcon icon={faGlobe} size='4x' />}
            title='Culture'
            content={[
                "At PFS, we're more than just a service provider. We're your partner in success.",
                "We believe in teamwork, diversity, and open-mindedness, and we work together to achieve your goals.",
                "You can trust that our team will value your unique perspective and ideas, and will always be invested in your success"
            ]}
        />
    )
}

export default ReasonCulture