import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useGoogleTracking = () => {
  const trackingId = process.env.REACT_APP_GA_MEASUREMENT_ID;
  const location = useLocation();

  useEffect(() => {
    window.gtag('config', trackingId, {
      page_path: location.pathname,
    });

    const unlisten = () => {
      // do nothing
    };
    return unlisten;
  }, [trackingId, location]);
};
