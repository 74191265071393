import { useRollbar } from '@rollbar/react';
import { notification } from 'antd';
import React, { useCallback, useState } from 'react';
import submitContactForm from '../../lib/ContactFormPost';
import styles from './PFSContactForm.module.css';

const initialFormData = {
  fName: '',
  lName: '',
  phone: '',
  email: '',
  message: '',
  subscribe: false,
};

const PFSContactForm = () => {
  const [formData, setFormData] = useState(initialFormData);

  const { fName, lName, email, phone, message, subscribe } = formData;
  const [isDisabled, setDisabled] = useState(false);

  const rollbar = useRollbar();

  const handleValueChange = (ev) => {
    let input = ev.target;
    setFormData({ ...formData, [input.name]: input.value });
  };

  const handleCheckboxChange = useCallback((e) => {
    setFormData({ ...formData, subscribe: e.target.checked });
  }, [formData, setFormData]);
  
  const captchaCode = useCallback(async () => {
    let newCode = await window.grecaptcha.execute(
      process.env.REACT_APP_CAPTCHA_KEY,
      { action: 'contact_form' },
    );
    return newCode;
  }, []);

  const handleSuccess = useCallback(() => {
    if(subscribe) {
      localStorage.setItem('subscribe', true)
    }
    notification.success({
      message: 'Your message has been sent!',
      description: 'Thank you for your message! We will reach out to you as soon as possible.'
    });
  }, [subscribe]);

  const handleFailure = useCallback((messages) => {
    messages.forEach((message) => {
      notification.error({
        message: 'Oops! There was a problem',
        description: message,
      });
    });
  }, []);

  const handleSubmit = useCallback(async (ev) => {
    ev.preventDefault();

    setDisabled(true);
    let contactResponse;

    try {
      let contactBody = { ...formData }
      contactBody.captcha = await captchaCode();
      contactResponse = await submitContactForm(contactBody);
      if (contactResponse.data.success) {
        handleSuccess();
      } else {
        handleFailure(contactResponse.data.errors);
      }
    } catch (e) {
      rollbar.error(e);
      handleFailure(['Looks like there was a problem. Please reach out to us over email instead']);
    } finally {
      setDisabled(false);
    }

  }, [captchaCode, formData, handleFailure, handleSuccess, rollbar]);

  return (
    <div className={styles.PFSContactForm} data-testid="PFSContactForm">
      <div id={styles.FormContainer}>
        <form data-testid="ContactFormElement" onSubmit={handleSubmit}>
          <div className={styles.NameInputs}>
            <input disabled={isDisabled} type='text' name='fName' placeholder='First Name' value={fName} onChange={handleValueChange} />
          </div>
          <div className={styles.NameInputs}>
            <input disabled={isDisabled} type='text' name='lName' placeholder='Last Name' value={lName} onChange={handleValueChange} />
          </div>
          <input disabled={isDisabled} type='email' name='email' placeholder='Email Address' value={email} onChange={handleValueChange} />
          <input disabled={isDisabled} type='tel' name='phone' placeholder='Phone Number' value={phone} onChange={handleValueChange} />
          <textarea disabled={isDisabled} name='message' placeholder='Message' value={message} onChange={handleValueChange} />
          <div className={styles.checkboxContainer}>
            <input data-testid="subscribe-checkbox" disabled={isDisabled} type="checkbox" className={styles.checkbox} value={subscribe} onChange={handleCheckboxChange}/>
            <label>Subscribe to mailing list</label>
          </div>
          <input disabled={isDisabled} type='submit' value={isDisabled ? "Loading..." : "Submit Message"} />
        </form>
      </div>
    </div>
  );
};

PFSContactForm.propTypes = {};

PFSContactForm.defaultProps = {};

export default PFSContactForm;
