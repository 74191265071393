import React from 'react';
import PropTypes from 'prop-types';
import styles from './PFSBulletPoints.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
const PFSBulletPoints = (props) => {
  const { title, items } = props;
  return (
    <div className={styles.PfsBulletPoints} data-testid='PfsBulletPoints'>
      <p data-testid='PfsBulletPointsTitle' className={styles.BulletTitle}>
        {title}
      </p>
      {items?.map((i, idx) => (
        <p
          data-testid='PfsBulletPointsItem'
          key={idx}
          className={styles.Bullet}
        >
          <FontAwesomeIcon
            className={styles.BulletIcon}
            size='1x'
            icon={faCheck}
          />
          {i}
        </p>
      ))}
      <div className={styles.endLine}></div>
    </div>
  );
};

PFSBulletPoints.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

PFSBulletPoints.defaultProps = {};

export default PFSBulletPoints;
