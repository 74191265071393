import { faHandshake } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import BaseReason from './BaseReason';

const ReasonCommitment = () => {
    return (
        <BaseReason
            icon={<FontAwesomeIcon icon={faHandshake} size='4x' />}
            title='Commitment'
            content={[
                "At PFS, we are committed to your success. We work hard to earn your trust and build a long-lasting relationship with you.",
                "Our team is loyal, reliable, and accountable and we are always striving to deliver service that exceeds your expectations"
            ]}
        />
    )
}

export default ReasonCommitment