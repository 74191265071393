import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './PFSServicePoints.module.css';

const PFSServicePoints = (props) => {
  const renderListItems = useCallback((pointsArray) => {
    return pointsArray.map((val, idx) => {
      if (typeof val === 'string') {
        return <li key={idx}>{val}</li>;
      } else if (typeof val === 'object' && val !== null && val.hasOwnProperty('subpoints')) {
        return (
          <li key={idx}>
            {val.label}
            <ul className={styles.unOrderedList}>
              {renderListItems(val.subpoints)}
            </ul>
          </li>
        );
      }
      return null;
    });
  }, []);

  return(
    <div className={styles.PFSServicePoints} data-testid="PFSServicePoints">
      <p className={styles.PointsHeader}>{props.title}</p>
      <ul>
        { renderListItems(props.points) }
      </ul>
    </div>
  )
};

PFSServicePoints.propTypes = {
  title: PropTypes.string,
  points: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        subpoints: PropTypes.arrayOf(PropTypes.string).isRequired,
      }),
    ])
  ).isRequired,
};

PFSServicePoints.defaultProps = {
  title: undefined,
  points: undefined,
};

export default PFSServicePoints;
