import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './ExternalRedirect.module.css';
import globalStyles from '../../styles/global.module.css';
import ContentContainer from '../ContentContainer/ContentContainer';

const ExternalRedirect = (props) => {
  const [, setCountdownTimeout ] = useState(undefined);
  const [secondsRemaining, setSecondsRemaining] = useState(3);

  const countDown = useCallback(() => {
    if(secondsRemaining < 1) {
      window.location.assign(props.url);
    } else {
      setSecondsRemaining(secondsRemaining - 1);
    }
  }, [props.url, secondsRemaining]);

  useEffect(() => {
    setCountdownTimeout(setTimeout(() => {
      countDown();
    }, 1000))

    return(() => {})
  }, [countDown]);

  return(
    <div className={styles.ExternalRedirect} data-testid="ExternalRedirect">
      <ContentContainer>
        <p data-testid='ExternalRedirectTitle' className={globalStyles.TitleText} >{props.title}</p>
        <p className={[globalStyles.SubtitleText, globalStyles.Fade].join(' ')} >Redirecting In {secondsRemaining}...</p>
        <br /><br /><br />
        <p className={styles.RedirectText}>You are being redirected to our <a data-testid='ExternalRedirectLink' href={props.url}>{props.title}</a> page</p>
      </ContentContainer>
    </div>
  )
};

ExternalRedirect.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

ExternalRedirect.defaultProps = {
  url: undefined,
  title: undefined,
};

export default ExternalRedirect;
