import React from 'react';
import styles from './PFSWaveContainer.module.css';
import PropTypes from 'prop-types';
import PFSWaveTop from './PFSWaveTop';
import PFSWaveBottom from './PFSWaveBottom';

const PFSWaveContainer = (props) => {
  const { color } = props;
  return (
    <div className={styles.PFSWaveContainer} data-testid='PFSWaveContainer'>
      <div style={{ marginBottom: '-1px' }} className={styles.WaveContainer}>
        <PFSWaveTop color={color} />
      </div>
      <div
        data-testid='WaveContentWrapper'
        id={styles.ContentWrapper}
        style={{ backgroundColor: color || '#153459' }}
      >
        {props.children}
      </div>
      <div style={{ marginTop: '-1px' }} className={styles.WaveContainer}>
        <PFSWaveBottom color={color} />
      </div>
    </div>
  );
};

PFSWaveContainer.propTypes = {
  color: PropTypes.string,
};

PFSWaveContainer.defaultProps = {
  color: '#153459',
};

export default PFSWaveContainer;
