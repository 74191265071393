import React from 'react';
import ContentContainer from '../ContentContainer/ContentContainer';
import styles from './PFSWhatWeDoIndex.module.css';
import globalStyles from '../../styles/global.module.css';

import PFSServiceCard from '../PFSServiceCard/PFSServiceCard';
import {
  PayrollSvg,
  BookKeepingSvg,
  LawSvg,
  TaxSvg,
  HCMSvg,
  AdvisorsSvg
} from '../PFSWhoWeAreContent/Svg';

import PFSContactCta from '../PFSContactCta/PFSContactCta';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';

export const WhatWeDoBreadcrumb = {
  label: 'What We Do',
  url: '/what-we-do'
};

const PFSWhatWeDoIndex = () => {
  useBreadcrumb([ WhatWeDoBreadcrumb ]);

  return (
    <div className={styles.PFSWhatWeDoIndex} data-testid='PFSWhatWeDoIndex'>
      <ContentContainer>
        <p id={styles.PageHeader} className={globalStyles.TitleText}>
          AgencyPoint
        </p>
        <p
          id={styles.PageSubtitle}
          className={[globalStyles.SubtitleText, globalStyles.Fade].join(' ')}
        >
          Solving for the Independent Insurance Agency
        </p>
        <div id={styles.ServiceCardsContainer}>
          <div className={styles.ServiceCardContainer}>
            <PFSServiceCard
              icon={<BookKeepingSvg />}
              title={'Bookkeeping'}
              url={'/what-we-do/bookkeeping'}
            />
          </div>
          <div className={styles.ServiceCardContainer}>
            <PFSServiceCard
              icon={<TaxSvg />}
              title={'Tax'}
              url={'/what-we-do/taxes'}
            />
          </div>
          <div className={styles.ServiceCardContainer}>
            <PFSServiceCard
              icon={<PayrollSvg />}
              title={'Payroll'}
              url={'/what-we-do/payroll'}
            />
          </div>
          <div className={styles.ServiceCardContainer}>
            <PFSServiceCard
              icon={<LawSvg />}
              title={'Law, APC'}
              subtitle={'PFS'}
              url={'/what-we-do/law'}
            />
          </div>
          <div className={styles.ServiceCardContainer}>
            <PFSServiceCard
              icon={<HCMSvg />}
              title={'Human Capital Management'}
              url={'/what-we-do/human-capital'}
            />
          </div>
          <div className={styles.ServiceCardContainer}>
            <PFSServiceCard
              icon={<AdvisorsSvg />}
              title={'Advisory'}
              url={'/what-we-do/advisors'}
            />
          </div>
        </div>
        <PFSContactCta ctaText="Call us today!" />
      </ContentContainer>
    </div>
  );
};

PFSWhatWeDoIndex.propTypes = {};

PFSWhatWeDoIndex.defaultProps = {};

export default PFSWhatWeDoIndex;
