import React from 'react';
import globalStyles from '../../styles/global.module.css';
import styles from './PFSTaxRates.module.css';
import ContentContainer from '../ContentContainer/ContentContainer';
import PFSHorizontalTableComponent from '../PFSHorizontalTableComponent/PFSHorizontalTableComponent';
import PFSVerticalTableComponent from '../PFSVerticalTableComponent/PFSVerticalTableComponent';
import PFSContactCta from '../PFSContactCta/PFSContactCta';
import { horizontalData, verticalData } from './taxRatesData';

import { WhatWeDoTaxesBreadcrumb } from '../PFSWhatWeDoTaxes/PFSWhatWeDoTaxes';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { useLocation } from 'react-router-dom';

const PFSTaxRates = () => {
  const { pathname } = useLocation();

  useBreadcrumb([
    ...WhatWeDoTaxesBreadcrumb,
    {
      label: 'Tax Rates',
      url: pathname,
    },
  ]);
  return(
  <div className={styles.PfsTaxRates} data-testid='PfsTaxRates'>
    <ContentContainer>
      <center className={globalStyles.SubtitleText}>
        <b>Tax Rates</b> <small>({new Date().getFullYear()} Rates)</small>
      </center>
      {horizontalData.map((d, idx) => (
        <PFSHorizontalTableComponent
          key={idx}
          title={d.title}
          headers={d.headers}
          rows={d.rows}
        />
      ))}
      {verticalData.map((d, idx) => (
        <PFSVerticalTableComponent
          key={idx}
          title={d.title}
          headers={d.headers}
          rows={d.rows}
        />
      ))}
    </ContentContainer>
    <br />
    <br />
    <br />
    <br />
    <br />
    <PFSContactCta ctaText='Call us today!' />
  </div>
)};

PFSTaxRates.propTypes = {};

PFSTaxRates.defaultProps = {};

export default PFSTaxRates;
