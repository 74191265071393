import React, { useState, useEffect } from 'react';
import styles from './PFSArticleShow.module.css';
import loaderStyle from '../PFSArticleList/PFSArticleList.module.css';
import { useLocation, useParams } from 'react-router-dom';
import ContentContainer from '../ContentContainer/ContentContainer';
import PFSContactCta from '../PFSContactCta/PFSContactCta';
import ArticleBanner from '../ArticleBanner/ArticleBanner';
import ArticleLayout from '../ArticleLayout/ArticleLayout';
import PFS404Page from '../PFS404Page/PFS404Page';
import { getBlogsData } from '../../lib/GetBlogData';
import { useRollbar } from '@rollbar/react';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { ArticlesIndexBreadcrumb } from '../PFSArticlesIndex/PFSArticlesIndex';

const PFSArticleShow = () => {
  const { slug } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(Boolean(slug));
  const rollbar = useRollbar();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!slug) return;

    setLoading(true);
    getBlogsData(slug)
      .then((response) => setArticle(response))
      .catch((e) => {
        rollbar.error(e);
        setArticle(null);
      })
      .finally(() => setLoading(false));
  }, [rollbar, slug]);

  useBreadcrumb([ArticlesIndexBreadcrumb, {
    label: article?.title,
    url: pathname,
  }])

  const renderArticle = (article) => (
    <>
      <ContentContainer>
        <ArticleBanner
          title={article.title}
          image={article.bannerImage}
          publishedAt={article.publishedAt}
        />
        <ArticleLayout author={article.author}>
          <div dangerouslySetInnerHTML={{ __html: article.content }}></div>
        </ArticleLayout>
      </ContentContainer>
      <br />
      <br />
      <br />
      <br />
      <PFSContactCta
        ctaText='Free Consultation!'
        metaText='Call us today to receive your'
      />
    </>
  );

  return (
    <div className={styles.PfsArticleShow} data-testid='PfsArticleShow'>
      {loading ? (
        <div className={loaderStyle.Loader}></div>
      ) : article ? (
        renderArticle(article)
      ) : (
        <PFS404Page />
      )}
    </div>
  );
};

export default PFSArticleShow;
