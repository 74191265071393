import React from 'react';
import styles from './PFSHomeBanner.module.css';
import ContentContainer from '../ContentContainer/ContentContainer';

import Slider from 'react-slick';

import apImage from './APAnnounce.png';
import huntImage from './HuntAnnounce.png';
import paradisoImage from './ParadisoAnnounce.png';
import wallaceImage from './WallaceAnnounce.png';
import { Link } from 'react-router-dom';

const PFSHomeBanner = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <ContentContainer>
      <div className={styles.PFSHomeBanner} data-testid="PFSHomeBanner">
        <div className={styles.CTAText}>
          <h1 id={styles.HeaderText}>
            Solving for
            <br></br>
            <span id={styles.BoldHeader}>the Independent Insurance Agency.</span>
          </h1>
          <p id={styles.BannerText}>
            Agency Point, Powered by PFS Global, is a trusted financial management partner, providing innovative solutions to hundreds of independent insurance agencies across the United States.
          </p>
          <Link to={'/what-we-do'} id={styles.LearnMoreBtn}>
            Learn More.
          </Link>
        </div>
        <div className={styles.BannerGraphic}>
          <Slider {...settings}>
            <div className={styles.BannerItem}>
              <img alt='PFS Banner 1' src={apImage} />
            </div>
            <div className={styles.BannerItem}>
              <img alt='PFS Banner 2' src={paradisoImage} />
            </div>
            <div className={styles.BannerItem}>
              <img alt='PFS Banner 3' src={wallaceImage} />
            </div>
            <div className={styles.BannerItem}>
              <img alt='PFS Banner 4' src={huntImage} />
            </div>
          </Slider>
        </div>
      </div>
    </ContentContainer>
  )
};

PFSHomeBanner.propTypes = {};

PFSHomeBanner.defaultProps = {};

export default PFSHomeBanner;
