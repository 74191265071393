import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import BaseReason from './BaseReason';

const ReasonForwardThinking = () => {
    return (
        <BaseReason
            icon={<FontAwesomeIcon icon={faLightbulb} size='4x' />}
            title='Forward Thinking'
            content={[
                "At PFS, we are committed to staying at the forefront of our industry.",
                "We leverage the latest technology and tools to streamline your processes and make your life easier.",
                "Whether it's adopting new software, or implementing new strategies, we are always looking for ways to help you save time, money, and effort."
            ]}
        />
    )
}

export default ReasonForwardThinking