import React, { useState } from 'react';
import styles from './PFSHeader.module.css';
import PFSLogo from '../PFSLogo/PFSLogo';
import ContentContainer from '../ContentContainer/ContentContainer';
import HeaderCallUs from '../HeaderCallUs/HeaderCallUs';
import MobileNavButton from '../MobileNavButton/MobileNavButton';
import NavMenuItems from '../NavMenuItems/NavMenuItems';
import MobileNavMenu from '../MobileNavMenu/MobileNavMenu';
import { NavLink } from 'react-router-dom';
import PFSBreadCrumb from '../PFSBreadCrumb/PFSBreadCrumb';
import PFSSubscribeBanner from '../PFSSubscribeBanner/PFSSubscribeBanner';
import PFSIcon from '../PFSIcon/PFSIcon';

const PFSHeader = () => {
  const [ navOpen, setNavOpen ] = useState(false);

  function toggleOpen() {
    setNavOpen(!navOpen);
  }

  return (
    <div className={styles.PFSHeader} data-testid="PFSHeader">
      <PFSSubscribeBanner />
      <ContentContainer>
        <div id={styles.HeaderPadding}>
          <NavLink to={'/'} className={styles.logo}>
            <PFSLogo imgSize={220} />
          </NavLink>
          <NavLink to={'/'} className={styles.icon}>
            <PFSIcon imgSize={52} />
          </NavLink>
          <div id={styles.RightSideContainer}>
            <NavMenuItems
              ListClass={styles.DesktopNavList}
              ListItemClass={styles.DesktopNavListItem}
              LinkActiveClass={styles.NavActive}
            />
            <HeaderCallUs />
            <MobileNavButton
              isOpen={navOpen}
              updateNavToggle={toggleOpen}
            />
          </div>
        </div>
        <PFSBreadCrumb/>
      </ContentContainer>
      <MobileNavMenu
        isOpen={navOpen}
        updateNavToggle={toggleOpen}
      />
    </div>
  )
};

PFSHeader.propTypes = {};

PFSHeader.defaultProps = {};

export default PFSHeader;
