import React from 'react';
import styles from './PFSPartnersIndex.module.css';
import ContentContainer from '../ContentContainer/ContentContainer';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import PFSServiceCard from '../PFSServiceCard/PFSServiceCard';
import ascendLogo from '../PFSAscend/Ascend_logo.png';

export const PartnersIndexBreadcrumb = {
  label: 'Partners',
  url: '/partners',
};

const PFSPartnersIndex = () => {
  useBreadcrumb([PartnersIndexBreadcrumb]);

  return (
    <div className={styles.PfsPartnersIndex} data-testid="PFSPartnersIndex">
      <ContentContainer>
        <center>
          <h1>PFS Global: Partners</h1>
        </center>
        <center>
          <p>
            We look forward to collaborating with you! If you're interested in
            partnering with PFS Global, please contact us at{' '}
            <a className={styles.PfsEmail} href="mailto:info@pfsonline.com">
              Info@pfsonline.com
            </a>
            . Our team will respond promptly to discuss how we can work
            together.
          </p>
        </center>
        <br />
        <br />
        <br />
        <section className={styles.ServiceCardSection}>
          <div className={styles.ServiceCardContainer}>
            <PFSServiceCard
              icon={<img alt="Ascend" src={ascendLogo} width="128em" />}
              subtitle="Technology"
              title="Ascend"
              url="/partners/ascend"
            />
          </div>
        </section>

        <br />
        <br />
        <br />
        <br />
        <br />
      </ContentContainer>
    </div>
  );
};

PFSPartnersIndex.propTypes = {};

PFSPartnersIndex.defaultProps = {};

export default PFSPartnersIndex;
