import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './PFSStaffMember.module.css';
import placeholderImage from './empty.png';
import LoadingImage from '../LoadingImage/LoadingImage';

const PFSStaffMember = (props) => {
  const {
    id,
    className,
    nameClassName,
    titleClassName,
  } = props;

  const dynamicClassName = useMemo(() => {
    return [
      styles.PFSStaffMember,
      className
    ].filter(Boolean).join(' ');
  }, [className]);

  const personTitleClassName = [
    styles.PersonTitle,
    titleClassName
  ].filter(Boolean).join(' ');

  return (
    <div
      id={id ?? ''}
      className={dynamicClassName}
      data-testid='PFSStaffMember'
    >
      <center>
        <div className={styles.ImageContainer}>
          <LoadingImage
            placeholderImage={placeholderImage}
            image={props.image}
            styleclass={styles.PFSStaffMemberImage}
            altText='Staff Member'
          />
        </div>
        <p className={nameClassName} data-testid='PFSStaffMemberName'>
          {props.name}
        </p>
        <p className={personTitleClassName} data-testid='PFSStaffMemberTitle'>
          {props.title}
        </p>
      </center>
    </div>
  );
};

PFSStaffMember.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  id: PropTypes.string,
};

PFSStaffMember.defaultProps = {
  name: undefined,
  title: undefined,
  image: undefined,
  id: undefined,
};

export default PFSStaffMember;
