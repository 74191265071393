import React, { useState, useCallback } from 'react';
import { useRollbar } from '@rollbar/react';
import { Modal, notification } from 'antd';
import styles from './PFSSubscribeModal.module.css';
import subscribe from '../../lib/SubscribeToMailList';

const PFSSubscribeModal = (props) => {
  const rollbar = useRollbar();
  const { open, setOpen, setShowBanner } = props;
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const { firstName, lastName, email } = formData;
  const handleValueChange = (ev) => {
    let input = ev.target;
    setFormData({ ...formData, [input.name]: input.value });
  };
  const handleFailure = useCallback((messages) => {
    messages.forEach((message) => {
      notification.error({
        message: 'Oops! There was a problem',
        description: message,
      });
    });
  }, []);
  const handleSuccess = useCallback(() => {
    notification.success({
      message: 'Thank you for subscribing!',
      description:
        'You have successfully joined our mailing list and will now receive our latest updates and news.',
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await subscribe(email, firstName, lastName);

      if (response.data.success) {
        localStorage.setItem('subscribe', true);
        handleSuccess();
        setOpen(false);
        setShowBanner(false);
      } else {
        handleFailure(response.data.errors);
      }
    } catch (e) {
      rollbar.error(e);
      handleFailure(['Looks like there was a problem. Please try again later']);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    if (!loading) {
      setOpen(false);
    }
  };
  return (
    <>
      <Modal
        className={styles.Modal}
        visible={open}
        onCancel={handleCancel}
        footer={null}
      >
        <div id={styles.FormContainer}>
          <h4>Subscribe to our mailing list</h4>
          <p>Sign up here to get news, tips, and updates from PFS!</p>
          <form data-testid="SubscribeForm" onSubmit={handleSubmit}>
            <div className={styles.InputsSection}>
              <input
                type="text"
                name="firstName"
                required
                placeholder="First Name"
                value={firstName}
                onChange={handleValueChange}
              />

              <input
                type="text"
                name="lastName"
                required
                placeholder="Last Name"
                value={lastName}
                onChange={handleValueChange}
              />
            </div>
            <div className={styles.InputsSection}>
              <input
                className={styles.emailInput}
                type="email"
                name="email"
                required
                placeholder="Email"
                value={email}
                onChange={handleValueChange}
              />

              <button
                className={styles.submitButton}
                disabled={loading}
                type="submit"
                data-testid="Submit-Button"
              >
                Subscribe
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};
export default PFSSubscribeModal;
