import React from "react";
import PropTypes from 'prop-types'
import styles from './BaseReason.module.css';

const BaseReason = ({icon, title, content}) => (
  <div className={styles.Content}>
    <div className={styles.IconWrapper}>
      { icon }
    </div>
    <h2>{ title }</h2>
    {content.map((paragraph, index) => (
      <p key={index}>{paragraph}</p>
    ))}
  </div>
)

BaseReason.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default BaseReason
