import React, { useState } from 'react';
import ContentContainer from '../ContentContainer/ContentContainer';
import styles from './PFSWhyChoosePFS.module.css';
import globalStyles from '../../styles/global.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faHandshake, faLightbulb, faShield, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'antd';
import ReasonIntegrity from './ReasonIntegrity';
import ReasonForwardThinking from './ReasonForwardThinking';
import ReasonQuality from './ReasonQuality';
import ReasonCommitment from './ReasonCommitment';
import ReasonCulture from './ReasonCulture';

const PFSWhyChoosePFS = () => {
  const [selectedReason, setSelectedReason] = useState(null);

  const handleReasonClick = (reason) => {
    setSelectedReason(reason);
  };

  const handleModalClose = () => {
    setSelectedReason(null);
  };

  return (
    <div className={styles.PFSWhyChoosePFS} data-testid='PFSWhyChoosePFS'>
      <ContentContainer>
        <center>
          <p className={globalStyles.SubtitleText}>
            Why Choose <b>AgencyPoint</b>?
          </p>
        </center>
        <center className={styles.ReasonsContainer}>
          <div className={styles.Reason} onClick={() => handleReasonClick(<ReasonIntegrity />)}>
            <div className={styles.IconContainer}>
              <FontAwesomeIcon icon={faShield} size='4x' />
            </div>
            <p className={styles.TitleContainer}>Integrity</p>
            <p className={styles.DescriptionContainer}>
              Honesty, Trustworthy, Professional, Respectful, Responsible
            </p>
          </div>
          <div className={styles.Reason} onClick={() => handleReasonClick(<ReasonForwardThinking />)}>
            <div className={styles.IconContainer}>
              <FontAwesomeIcon icon={faLightbulb} size='4x' />
            </div>
            <p className={styles.TitleContainer}>Forward Thinking</p>
            <p className={styles.DescriptionContainer}>
              Growth, Technology Driven, Creative, Innovative, Resourceful,
              Inspirational, Self-Aware
            </p>
          </div>
          <div className={styles.Reason} onClick={() => handleReasonClick(<ReasonQuality />)}>
            <div className={styles.IconContainer}>
              <FontAwesomeIcon icon={faAward} size='4x' />
            </div>
            <p className={styles.TitleContainer}>Quality</p>
            <p className={styles.DescriptionContainer}>
              Customer Focused, Responsiveness, Accurate, Efficient, Organized,
              Consistent, Improvement, Polished, Good Communication
            </p>
          </div>
          <div className={styles.Reason} onClick={() => handleReasonClick(<ReasonCommitment />)}>
            <div className={styles.IconContainer}>
              <FontAwesomeIcon icon={faHandshake} size='4x' />
            </div>
            <p className={styles.TitleContainer}>Commitment</p>
            <p className={styles.DescriptionContainer}>
              Hardworking, Ambition, Loyal, Reliable, Accountable, Opportunity
              Based on Merit
            </p>
          </div>
          <div className={styles.Reason} onClick={() => handleReasonClick(<ReasonCulture />)}>
            <div className={styles.IconContainer}>
              <FontAwesomeIcon icon={faGlobe} size='4x' />
            </div>
            <p className={styles.TitleContainer}>Culture</p>
            <p className={styles.DescriptionContainer}>
              Teamwork, Enjoyable, Diverse, Shared Success, Caring, Open-Minded
            </p>
          </div>
        </center>
        <Modal
          visible={selectedReason}
          onCancel={handleModalClose}
          footer={null}
        >
          {selectedReason}
        </Modal>
      </ContentContainer>
    </div>
  );
};

PFSWhyChoosePFS.propTypes = {};

PFSWhyChoosePFS.defaultProps = {};

export default PFSWhyChoosePFS;