import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { NavLink } from 'react-router-dom';
import ContentContainer from '../ContentContainer/ContentContainer';
import styles from './PFS404Page.module.css';

const PFS404Page = () => (
  <div className={styles.PFS404Page} data-testid="PFS404Page">
    <ContentContainer>
      <div id={styles.TextContainer}>
        <h2>
          <em>Sorry!</em>
        </h2>
        <p>We can't find that page.</p>
        <p>Try visiting our <NavLink data-testid='404HomePageLink' to={'/'}>home page</NavLink></p>
      </div>
      <div id={styles.IconContainer}>
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </div>
    </ContentContainer>
  </div>
);

PFS404Page.propTypes = {};

PFS404Page.defaultProps = {};

export default PFS404Page;
