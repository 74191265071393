import React from 'react';
import globalStyles from '../../styles/global.module.css';
import styles from './PFSRecordRetentions.module.css';
import ContentContainer from '../ContentContainer/ContentContainer';
import PFSPanel from '../PFSPanel/PFSPanel';
import PFSTabs from '../PFSTabs/PFSTabs';
import PFSContactCta from '../PFSContactCta/PFSContactCta';
import { BusinessRecords, PersonalRecords } from './recordsData';
import { WhatWeDoTaxesBreadcrumb } from '../PFSWhatWeDoTaxes/PFSWhatWeDoTaxes';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { useLocation } from 'react-router-dom';


const PFSRecordRetentions = () => {
  const { pathname } = useLocation();

  useBreadcrumb([
    ...WhatWeDoTaxesBreadcrumb,
    {
      label: 'Record Retention Guides',
      url: pathname,
    },
  ]);

  return(
  <div className={styles.PfsRecordRetentions} data-testid='PfsRecordRetentions'>
    <ContentContainer>
      <center className={globalStyles.SubtitleText}>
        <b>Record Retention Guides</b>
      </center>
      <PFSPanel>
        <div className={styles.heading}>
          Storing tax records: How long is long enough?
        </div>
        <p className={styles.paragraph}>
          April 15 has come and gone and another year of tax forms and shoeboxes
          full of receipts is behind us. But what should be done with those
          documents after your check or refund request is in the mail?
        </p>
        <p className={styles.paragraph}>
          Federal law requires you to maintain copies of your tax returns and
          supporting documents for three years. This is called the “three-year
          law” and leads many people to believe they’re safe provided they
          retain their documents for this period of time.
        </p>
        <p className={styles.paragraph}>
          However, if the IRS believes you have significantly underreported your
          income (by 25 percent or more), it may go back six years in an audit.
          If there is any indication of fraud, or you do not file a return, no
          period of limitation exists.To be safe, use the following guidelines.
        </p>
      </PFSPanel>
      <PFSTabs
        items={[
          {
            label: 'Business Records',
            key: '1',
            children: <BusinessRecords />,
          },
          {
            label: 'Personal Records',
            key: '2',
            children: <PersonalRecords />,
          },
        ]}
      />
    </ContentContainer>
    <br />
    <br />
    <br />
    <br />
    <br />
    <PFSContactCta ctaText='Call us today!' />
  </div>
)};

PFSRecordRetentions.propTypes = {};

PFSRecordRetentions.defaultProps = {};

export default PFSRecordRetentions;
