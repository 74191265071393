import React from 'react';
import PropTypes from 'prop-types';
import styles from './SharePanel.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

const SharePanel = (props) => {
  const { href } = window.location;
  const { header } = props;
  return (
    <div className={styles.SharePanel} data-testid='SharePanel'>
      <h1 data-testid='SharePanelHeader' className={styles.Header}>
        {header}
      </h1>
      <div>
        <a
          rel='noreferrer'
          target='_blank'
          href={`https://twitter.com/intent/tweet?url=${href}`}
        >
          <FontAwesomeIcon size='2x' icon={faTwitter} className={styles.Icon} />
        </a>
        <a
          rel='noreferrer'
          target='_blank'
          href={`http://www.facebook.com/share.php?u=${href}`}
        >
          <FontAwesomeIcon
            size='2x'
            icon={faFacebookF}
            className={styles.Icon}
          />
        </a>
        <a
          rel='noreferrer'
          target='_blank'
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${href}`}
        >
          <FontAwesomeIcon
            size='2x'
            icon={faLinkedinIn}
            className={styles.Icon}
          />
        </a>
        <a
          rel='noreferrer'
          target='_blank'
          href={`https://mail.google.com/mail/u/0/?view=cm&to&body=${href}`}
        >
          <FontAwesomeIcon
            size='2x'
            icon={faEnvelope}
            className={styles.Icon}
          />
        </a>
      </div>
    </div>
  );
};

SharePanel.propTypes = {
  header: PropTypes.string.isRequired,
};

SharePanel.defaultProps = {
  header: undefined,
};

export default SharePanel;
