export const RetryAmount = 3;
export const RetryTime = 3000;

const retryQuery = (_callback, maxRetries = RetryAmount, retryCount = 0) => {
  const tryAgain = (_resolve, _reject) => {
    setTimeout(() => {
      retryQuery(_callback, maxRetries, retryCount + 1)
        .then((result) => {
          _resolve(result);
        })
        .catch((e) => {
          _reject(e);
        });
    }, RetryTime);
  };

  return new Promise(async (resolve, reject) => {
    try {
      const response = await _callback();
      resolve(response);
    } catch (e) {
      const responseCode = e?.response?.status;
      if (responseCode >= 400 && responseCode < 500) {
        reject(e);
      } else if (retryCount >= maxRetries) {
        reject(e);
      } else {
        tryAgain(resolve, reject);
      }
    }
  });
};

export default retryQuery;