import React from 'react';
import styles from './PFSPanel.module.css';

const PFSPanel = (props) => (
  <div className={styles.PfsPanel} data-testid='PfsPanel'>
    {props.children}
  </div>
);

export default PFSPanel;
