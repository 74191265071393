import React from 'react';
import styles from './PFSHomepage.module.css';
import PFSHomeBanner from '../PFSHomeBanner/PFSHomeBanner';
import PFSServicesBanner from '../PFSServicesBanner/PFSServicesBanner';
import PFSContactCta from '../PFSContactCta/PFSContactCta';
import PFSArticleCta from '../PFSArticleCTA/PFSArticleCTA';

const PFSHomepage = () => (
  <div className={styles.PFSHomepage} data-testid='PFSHomepage'>
    <PFSHomeBanner />
    <PFSServicesBanner />
    <PFSArticleCta title='Blog Articles' browseText='Browse Articles' />
    <PFSContactCta ctaText='Call us today!' />
  </div>
);

PFSHomepage.propTypes = {};

PFSHomepage.defaultProps = {};

export default PFSHomepage;
