import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './AccordionSection.module.css';

const ArrowIcon = ({ color }) => (
  <svg
    width="20"
    height="20"
    className={styles.icon}
    fill={color}
    viewBox="0 0 16 16"
  >
    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
  </svg>
);

const LoadingIndicator = ({ color }) => (
  <center>
    <div
      style={{ borderTop: `0.5em solid ${color}` }}
      data-testid="AccordionSection-loader"
      className={styles.Loader}
    ></div>
  </center>
);

const AccordionSection = (props) => {
  const {
    autoScroll,
    isOpen,
    onToggle,
    loading,
    sectionId,
    title,
    secondaryColor,
    primaryColor,
    loaderColor,
    className,
    closedClassName,
    contentClassName,
  } = props;

  let classNames = [styles.Accordion, className];
  let closedClassNames = [styles.Closed, closedClassName]
  if(!isOpen) classNames.push(...closedClassNames);

  const accordion = useRef();

  const scrollToSection = () => {
    window.scrollTo({
      top: accordion.current?.offsetTop - 25,
      behavior: 'smooth',
    });
  };

  const handleClick = useCallback(() => {
    onToggle();
    if (isOpen) return;
    if (!autoScroll) return;
    setTimeout(scrollToSection, 0);
  }, [onToggle, isOpen, autoScroll]);

  return (
    <>
      <div
        ref={accordion}
        data-testid="AccordionSection"
        id={sectionId}
      >
        <div
          data-testid="Accordion"
          onClick={handleClick}
          className={classNames.join(' ')}
          style={{
            color: isOpen ? secondaryColor.color : primaryColor.color,
            backgroundColor: isOpen
              ? secondaryColor.backgroundColor
              : primaryColor.backgroundColor,
          }}
        >
          <div data-testid="Accordion-title">{title}</div>
          <ArrowIcon
            color={isOpen ? secondaryColor.color : primaryColor.color}
          />
        </div>
        {isOpen &&
          (loading ? (
            <LoadingIndicator color={loaderColor} />
          ) : (
            <div className={contentClassName} data-testid="Accordion-content">{
              props.children
            }</div>
          ))}
      </div>
    </>
  );
};

const colorShape = PropTypes.shape({
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
});

AccordionSection.propTypes = {
  className: PropTypes.string,
  closedClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  primaryColor: colorShape,
  secondaryColor: colorShape,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  loaderColor: PropTypes.string,
  autoScroll: PropTypes.bool,
  sectionId: PropTypes.string,
};
AccordionSection.defaultProps = {
  className: '',
  closedClassName: '',
  contentClassName: '',
  loaderColor: 'black',
  title: undefined,
  onToggle: undefined,
  loading: false,
  isOpen: undefined,
  autoScroll: false,
  sectionId: '',
  primaryColor: {
    color: 'black',
    backgroundColor: 'white',
  },
  secondaryColor: {
    color: 'white',
    backgroundColor: 'black',
  },
};

export default AccordionSection;
