import React, { useState } from 'react';
import PFSBody from './components/PFSBody/PFSBody';
import PFSFooter from './components/PFSFooter/PFSFooter';
import PFSHeader from './components/PFSHeader/PFSHeader';
import { useGoogleTracking } from './hooks/useGoogleTracking';

import { Provider, ErrorBoundary } from '@rollbar/react';
import BreadcrumbContext from './contexts/BreadcrumbContext';

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_KEY,
  environment: process.env.REACT_APP_ROLLBAR_ENV,
};

function App() {
  useGoogleTracking();
  const [ breadcrumbItems, setBreadcrumbItems ] = useState([]);

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <BreadcrumbContext.Provider value={{
          breadcrumbItems,
          setBreadcrumbItems
        }}>
          <PFSHeader />
          <PFSBody />
          <PFSFooter />
        </BreadcrumbContext.Provider>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
