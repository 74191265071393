import React from 'react';
import PFSBulletPoints from '../PFSBulletPoints/PFSBulletPoints';

export const BusinessRecords = () => {
  const data = [
    {
      title: 'Business Documents To Keep For One Year',
      items: [
        'Correspondence with Customers and Vendors',
        'Duplicate Deposit Slips',
        'Purchase Orders (other than Purchasing Department copy)',
        'Receiving Sheets',
        'Requisitions',
        'Stenographer’s Notebooks',
        'Stockroom Withdrawal Forms',
      ],
    },
    {
      title: 'Business Documents To Keep For Three Years',
      items: [
        'Employee Personnel Records (after termination)',
        'Employment Applications',
        'Expired Insurance Policies',
        'General Correspondence',
        'Internal Audit Reports',
        'Internal Reports',
        'Petty Cash Vouchers',
        'Physical Inventory Tags',
        'Savings Bond Registration Records of Employees',
        'Time Cards For Hourly Employees',
      ],
    },
    {
      title: 'Business Documents To Keep For Six Years',
      items: [
        'Accident Reports, Claims',
        'Accounts Payable Ledgers and Schedules',
        'Accounts Receivable Ledgers and Schedules',
        'Bank Statements and Reconciliations',
        'Cancelled Checks',
        'Cancelled Stock and Bond Certificates',
        'Employment Tax Records',
        'Expense Analysis and Expense Distribution Schedules',
        'Expired Contracts, Leases',
        'Expired Option Records',
        'Inventories of Products, Materials, Supplies',
        'Invoices to Customers',
        'Notes Receivable Ledgers, Schedules',
        'Payroll Records and Summaries, including payment to pensioners',
        'Plant Cost Ledgers',
        'Purchasing Department Copies of Purchase Orders',
        'Sales Records',
        'Subsidiary Ledgers',
        'Time Books',
        'Travel and Entertainment Records',
        'Vouchers for Payments to Vendors, Employees, etc.',
        'Voucher Register, Schedules',
      ],
    },
    {
      title: 'Business Records To Keep Forever',
      items: [
        'While federal guidelines do not require you to keep tax records “forever,” in many cases there will be other reasons you’ll want to retain these documents indefinitely.',
        'Audit Reports from CPAs/Accountants',
        'Cancelled Checks for Important Payments (especially tax payments)',
        'Cash Books, Charts of Accounts',
        'Contracts, Leases Currently in Effect',
        'Corporate Documents (incorporation, charter, by-laws, etc.)',
        'Documents substantiating fixed asset additions',
        'Deeds',
        'Depreciation Schedules',
        'Financial Statements (Year End)',
        'General and Private Ledgers, Year End Trial Balances',
        'Insurance Records, Current Accident Reports, Claims, Policies',
        'Investment Trade Confirmations',
        'IRS Revenue Agent Reports',
        'Journals',
        'Legal Records, Correspondence and Other Important Matters',
        'Minutes Books of Directors and Stockholders',
        'Mortgages, Bills of Sale',
        'Property Appraisals by Outside Appraisers',
        'Property Records',
        'Retirement and Pension Records',
        'Tax Returns and Worksheets',
        'Trademark and Patent Registrations',
      ],
    },
  ];

  return data.map((d, idx) => (
    <PFSBulletPoints key={idx} title={d.title} items={d.items} />
  ));
};
export const PersonalRecords = () => {
  const data = [
    {
      title: 'Personal Documents To Keep For One Year',
      items: [
        'While it’s important to keep year-end mutual fund and IRA contribution statements forever, you don’t have to save monthly and quarterly statements once the year-end statement has arrived.',
      ],
    },
    {
      title: 'Personal Documents To Keep For Three Years',
      items: [
        'Credit Card Statements',
        'Medical Bills (in case of insurance disputes)',
        'Utility Records',
        'Expired Insurance Policies',
      ],
    },
    {
      title: 'Personal Documents To Keep For Six Years',
      items: [
        'Supporting Documents For Tax Returns',
        'Accident Reports and Claims',
        'Medical Bills (if tax-related)',
        'Sales Receipts',
        'Wage Garnishments',
        'Other Tax-Related Bills',
      ],
    },
    {
      title: 'Personal Records To Keep Forever',
      items: [
        'CPA Audit Reports',
        'Legal Records',
        'Important Correspondence',
        'Income Tax Returns',
        'Income Tax Payment Checks',
        'Property Records / Improvement Receipts (or six years after property sold)',
        'Investment Trade Confirmations',
        'Retirement and Pension Records (Forms 5448, 1099-R and 8606 until all distributions are made from your IRA or other qualified plan)',
      ],
    },
    {
      title: 'Special Circumstances',
      items: [
        'Car Records (keep until the car is sold)',
        'Credit Card Receipts (keep until verified on your statement)',
        'Insurance Policies (keep for the life of the policy)',
        'Mortgages / Deeds / Leases (keep 6 years beyond the agreement)',
        'Pay Stubs (keep until reconciled with your W-2)',
        'Sales Receipts (keep for life of the warranty)',
        'Stock and Bond Records (keep for 6 years beyond selling)',
        'Warranties and Instructions (keep for the life of the product)',
        'Other Bills (keep until payment is verified on the next bill)',
        'Depreciation Schedules and Other Capital Asset Records (keep for 3 years after the tax life of the asset)',
      ],
    },
  ];

  return data.map((d, idx) => (
    <PFSBulletPoints key={idx} title={d.title} items={d.items} />
  ));
};
