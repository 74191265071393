import React from 'react';
import PropTypes from 'prop-types';
import styles from './PFSVerticalTableComponent.module.css';

const PFSVerticalTableComponent = (props) => {
  const { title, rows } = props;

  return (
    <div
      className={styles.PfsVerticalTableComponent}
      data-testid='PfsVerticalTableComponent'
    >
      <p data-testid='PfsVerticalTableTitle' className={styles.TableTitle}>
        {title}
      </p>
      {rows?.map((row, rIdx) => (
        <div
          key={rIdx}
          className={styles.TableRow}
          data-testid='PfsVerticalTableRow'
        >
          {row?.map((cell, cIdx) => (
            <div
              data-testid='PfsVerticalTableCell'
              key={cIdx}
              className={styles.TableCell}
            >
              {cell}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

PFSVerticalTableComponent.propTypes = {
  title: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
};

PFSVerticalTableComponent.defaultProps = {
  title: undefined,
  rows: undefined,
};

export default PFSVerticalTableComponent;
