import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './PFSTabs.module.css';

const PFSTabs = (props) => {
  const { items } = props;
  const [selectedItem, setSelectedItem] = useState(items?.[0]);

  return (
    <div className={styles.PfsTabs} data-testid='PfsTabs'>
      {items?.map((i) => (
        <span
          data-testid='PfsTabHeader'
          key={i.key}
          onClick={() => setSelectedItem(i)}
          className={`${styles.tab} ${
            selectedItem.key === i.key ? styles.selected : ''
          }`}
        >
          {i.label}
        </span>
      ))}
      <div> {selectedItem?.children} </div>
    </div>
  );
};
const tabShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
});
PFSTabs.propTypes = {
  items: PropTypes.arrayOf(tabShape).isRequired,
};

PFSTabs.defaultProps = {};

export default PFSTabs;
