import React from 'react';
import styles from './PFSYorke.module.css';
import ContentContainer from '../ContentContainer/ContentContainer';
import yorkeSvg from './yorke_cpa.svg'
import { NavLink, useLocation } from 'react-router-dom';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { AcquisitionsIndexBreadcrumb } from '../PFSAcquisitionsIndex/PFSAcquisitionsIndex';

const PFSYorke = () => {
  const { pathname } = useLocation();

  useBreadcrumb([AcquisitionsIndexBreadcrumb, {
    label: "Yorke",
    url: pathname
  }])

  return (
    <div className={styles.PfsYorke} data-testid='PfsYorke'>
      <ContentContainer>
        <img className={styles.logo} src={yorkeSvg} alt='Yorke' />
        <h1>Yorke & Associates CPAs has merged with PFS Global!</h1>
        <div className={styles.content}>
          <p>Dear Clients and Friends of the Firm:</p>
          <p>
            After 21 years of practicing as a self-employed practitioner, I am
            pleased to announce that Yorke &amp; Associates CPAs Inc. will be
            merging with Aaron Stocks of PFS effective November 1, 2022. The new
            firm is named PFS Tax CPAs LLP, and we will be moving our offices on
            October 22, 2022. The physical and mailing address of the merged firm
            will be:
          </p>
          <div>PFS Global</div>
          <div>556 N. Diamond Bar Blvd. Suite 101,</div>
          <div>Diamond Bar, CA 91765</div>
          <div>909-294-7372</div>
          <br />
          <p>
            The combined firm will not only have greater resources for taxes, but
            the merger also comes with the expanded services of PFS: bookkeeping,
            payroll, human capital management, legal, and technology.{' '}
          </p>
          <p>
            While I am very confident that the merger will only have a positive
            effect on you, I welcome any questions or concerns you might have.
          </p>
          <div>Sincerely,</div>
          <div>Richard Yorke, CPA MBA</div>
          <NavLink to={'/'} id={styles.homeBtn}>
            Continue
          </NavLink>
        </div>
      </ContentContainer>
    </div>
  )
};

export default PFSYorke;
