import React from 'react';
import PropTypes from 'prop-types';
import styles from './PFSServiceCard.module.css';

function resolveTitle(title) { 
  if(Array.isArray(title)) {
    return title.map((val, idx) =>  <p key={idx}>{val}</p>);
  }
  return title;
}

const PFSServiceCard = ({ icon, title, subtitle, url }) => (
  <a className={styles.PFSServiceCard} data-testid="PFSServiceCard" href={url}>
    <div className={styles.CardContentContainer}>
      <div className={styles.IconContainer}>
        { icon }
      </div>
      <div className={styles.SubtitleContainer}>
        { subtitle }
      </div>
      <div className={styles.TitleContainer} data-testid='CardTitle'>
        { resolveTitle(title) }
      </div>
    </div>
  </a>
);

PFSServiceCard.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  subtitle: PropTypes.string,
  url: PropTypes.string.isRequired,
};

PFSServiceCard.defaultProps = {
  icon: undefined,
  title: undefined,
  subtitle: '',
  url: undefined,
};

export default PFSServiceCard;
