import axios from 'axios';
import retryQuery from './RetryQuery';

const subscribe = (email, firstName, lastName) => {
  if (!email && !firstName && !lastName) {
    throw new Error('Error: Missing parameters');
  }
  return retryQuery(() =>
    axios.post(`${process.env.REACT_APP_API_URL}/SubscribeMailingList`, {
      email,
      firstName,
      lastName,
    })
  );
};

export default subscribe;
