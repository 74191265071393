import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PFS404Page from '../PFS404Page/PFS404Page';
import PFSWhatWeDoBookkeeping from '../PFSWhatWeDoBookkeeping/PFSWhatWeDoBookkeeping';
import PFSWhatWeDoHCM from '../PFSWhatWeDoHCM/PFSWhatWeDoHCM';
import PFSWhatWeDoIndex from '../PFSWhatWeDoIndex/PFSWhatWeDoIndex';
import PFSWhatWeDoLegal from '../PFSWhatWeDoLegal/PFSWhatWeDoLegal';
import PFSWhatWeDoPayroll from '../PFSWhatWeDoPayroll/PFSWhatWeDoPayroll';
import PFSWhatWeDoTaxes from '../PFSWhatWeDoTaxes/PFSWhatWeDoTaxes';

// tax tool pages
import PFSTaxRates from '../PFSTaxRates/PFSTaxRates';
import PFSDueDates from '../PFSDueDates/PFSDueDates';
import PFSRecordRetentions from '../PFSRecordRetentions/PFSRecordRetentions';

//tax tips pages
import PFSWhatWeDoAdvisors from '../PFSWhatWeDoAdvisors/PFSWhatWeDoAdvisors';

const PFSWhatWeDoRoutes = () => (
  <Routes>
    <Route path='bookkeeping' element={<PFSWhatWeDoBookkeeping />} />
    <Route exact path='taxes' element={<PFSWhatWeDoTaxes />} />
    <Route path='taxes/*'>
      <Route path='tax-rate-tool' element={<PFSTaxRates />} />
      <Route path='due-dates-tool' element={<PFSDueDates />} />
      <Route path='tax-guides-tool' element={<PFSRecordRetentions />} />
      <Route path='*' element={<PFS404Page />} />
    </Route>
    <Route path='payroll' element={<PFSWhatWeDoPayroll />} />
    <Route path='law' element={<PFSWhatWeDoLegal />} />
    {/* <Route path='technology' element={<PFSWhatWeDoTechnology />} /> */}
    <Route path='human-capital' element={<PFSWhatWeDoHCM />} />
    <Route path='advisors' element={<PFSWhatWeDoAdvisors />} />
    <Route path='/' element={<PFSWhatWeDoIndex />} />
    <Route path='*' element={<PFS404Page />} />
  </Routes>
);

PFSWhatWeDoRoutes.propTypes = {};

PFSWhatWeDoRoutes.defaultProps = {};

export default PFSWhatWeDoRoutes;
