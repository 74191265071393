import React, { useState, useEffect } from 'react';
import styles from './PFSArticlesIndex.module.css';
import globalStyles from '../../styles/global.module.css';
import PFSArticleList from '../PFSArticleList/PFSArticleList';
import { getBlogsData } from '../../lib/GetBlogData';
import { fuzzySearch } from '../../lib/FuzzySearch';
import { useRollbar } from '@rollbar/react';
import ContentContainer from '../ContentContainer/ContentContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { useBreadcrumb } from '../../hooks/useBreadcrumb';

export const ArticlesIndexBreadcrumb = {
  label: "What We Think",
  url: '/articles'
}

const PFSArticlesIndex = () => {
  const [blogs, setBlogs] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [numberOfArticles, setNumberOfArticles] = useState(5);
  const rollbar = useRollbar();

  useEffect(() => {
    getBlogsData()
      .then((response) => {
        setBlogs(response);
        setFilteredBlogs(response);
      })
      .catch((e) => {
        rollbar.error(e);
        setBlogs([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [rollbar]);

  const filterBlogs = (event) => {
    const query = event.target.value;
    const blogsFiltered = blogs.filter((blog) => (
      fuzzySearch(blog.title, query) || fuzzySearch(blog.author, query))
    )
    setFilteredBlogs(blogsFiltered);
  };

  useBreadcrumb([ ArticlesIndexBreadcrumb ])

  return (
    <div className={styles.PfsArticles} data-testid='PfsArticles'>
      <ContentContainer>
        <center>
          <p className={globalStyles.TitleText}>Articles</p>
          <input
            onChange={filterBlogs}
            type='search'
            className={styles.searchInput}
            placeholder='Search article...'
          />
          <FontAwesomeIcon className={styles.searchIcon} icon={faSearch} />
        </center>
        <PFSArticleList
          loading={loading}
          title={'All Latest'}
          list={filteredBlogs.slice(0, numberOfArticles)}
          canLoadMore={numberOfArticles < filteredBlogs.length}
          loadMoreText={'Show More Articles'}
          onLoadMore={() => setNumberOfArticles(numberOfArticles + 5)}
        />
      </ContentContainer>
    </div>
  );
};

PFSArticlesIndex.propTypes = {};

PFSArticlesIndex.defaultProps = {};

export default PFSArticlesIndex;
