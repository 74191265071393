import React from 'react';
import PropTypes from 'prop-types';
import styles from './NavMenuItems.module.css';
import { Dropdown, Menu } from 'antd';
import { NavLink } from 'react-router-dom';

const NavMenuItems = (props) => {
  const menu = (
    <Menu
      className={styles.dropdownMenu}
      items={[
        {
          key: '1',
          label: (
            <NavLink
              id='dropdownItem'
              to={'/what-we-do/taxes#tools'}
              onClick={props.OnNavCallback}
            >
              Tax Tools
            </NavLink>
          ),
        },
      ]}
    />
  );

  function SetActive(obj) {
    return `${obj.isActive ? props.LinkActiveClass : ''}`;
  }
  return (
    <ul
      className={`${styles.NavMenuItems} ${props.ListClass}`}
      data-testid='NavMenuItems'
    >
      <li className={`${props.ListItemClass}`}>
        <NavLink className={SetActive} to={'/'} onClick={props.OnNavCallback}>
          Home
        </NavLink>
      </li>
      <li className={`${props.ListItemClass}`}>
        <NavLink
          className={SetActive}
          to={'/what-we-do'}
          onClick={props.OnNavCallback}
        >
          What We Do
        </NavLink>
      </li>
      <li className={`${props.ListItemClass}`}>
        <NavLink
          className={SetActive}
          to={'/who-we-are'}
          onClick={props.OnNavCallback}
        >
          Who We Are
        </NavLink>
      </li>
      <li className={`${props.ListItemClass}`}>
        <NavLink
          className={SetActive}
          to={'/articles'}
          onClick={props.OnNavCallback}
        >
          What We Think
        </NavLink>
      </li>
      <li className={`${props.ListItemClass}`}>
        <NavLink
          className={SetActive}
          to={'/careers'}
          onClick={props.OnNavCallback}
        >
          Careers
        </NavLink>
      </li>
      <li className={`${props.ListItemClass}`}>
        <Dropdown
          overlay={menu}
          overlayClassName={styles.dropdown}
          placement='bottom'
        >
          <span className={styles.menuLink} data-testid='dropdownLink'>
            Tools
          </span>
        </Dropdown>
      </li>
      <li className={`${props.ListItemClass} ${styles.mobileLink}`}>
        <NavLink
          onClick={props.OnNavCallback}
          className={SetActive}
          to={'/what-we-do/taxes#tools'}
        >
          Tax Tools
        </NavLink>
      </li>
    </ul>
  );
};

NavMenuItems.propTypes = {
  ListItemClass: PropTypes.string,
  ListClass: PropTypes.string,
  LinkActiveClass: PropTypes.string,
  OnNavCallback: PropTypes.func,
};

NavMenuItems.defaultProps = {
  ListItemClass: '',
  ListClass: '',
  LinkActiveClass: 'active',
  OnNavCallback: () => {},
};

export default NavMenuItems;
