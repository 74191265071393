import React from 'react';
import PropTypes from 'prop-types';
import styles from './PFSHorizontalTableComponent.module.css';

const PFSHorizontalTableComponent = (props) => {
  const { title, headers, rows } = props;
  return (
    <div
      className={styles.PfsHorizontalTableComponent}
      data-testid='PfsHorizontalTableComponent'
    >
      <p data-testid='PfsHorizontalTabletitle' className={styles.Tabletitle}>
        {title}
      </p>
      <table className={styles.Table}>
        <thead>
          <tr className={styles.TableRow}>
            {headers?.map((h, index) => (
              <td
                key={index}
                data-testid='PFSHorizontalTableHeader'
                className={styles.TableCell + ' ' + styles.TableHeader}
              >
                {h}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows?.map((row, index) => (
            <tr key={index} data-testid='PFSHorizontalTableRow'>
              {row?.map((value, idx) => (
                <td
                  key={idx}
                  data-testid='PFSHorizontalTableCell'
                  className={styles.TableCell + ' ' + styles.TableBody}
                >
                  {value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
PFSHorizontalTableComponent.propTypes = {
  title: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.node)),
  ]).isRequired,
};
PFSHorizontalTableComponent.defaultProps = {
  title: undefined,
  headers: undefined,
  rows: undefined,
};

export default PFSHorizontalTableComponent;
