import React from 'react';
import styles from './PFSFooterNav.module.css';

import ContentContainer from '../ContentContainer/ContentContainer';
import QBIcon from '../QBIcon/QBIcon';
import { payUrl, uploadUrl, legalPayUrl, quickAssistUrl } from '../PFSBody/PFSBody';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUpFromBracket,
  faDollarSign,
  faDesktop,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faYelp,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

const PFSFooterNav = () => (
  <div className={styles.PFSFooterNav} data-testid='PFSFooterNav'>
    <ContentContainer>
      <div className={styles.NavSection}>
        <div className={styles.NavSectionHeader}>AgencyPoint Tools</div>
        <table className={styles.Table}>
          <tbody>
            <tr className={styles.NavToolsItem}>
              <td className={styles.NavToolsIcon}>
                <a href={quickAssistUrl} rel='noreferrer' target='_blank'>
                  <FontAwesomeIcon size='2x' icon={faDesktop} />
                </a>
              </td>
              <td className={styles.NavToolsText}>
                <a href={quickAssistUrl} rel='noreferrer' target='_blank'>
                  Quick Assist
                </a>
              </td>
            </tr>
            <tr className={styles.NavToolsItem}>
              <td className={styles.NavToolsIcon}>
                <a href={uploadUrl} rel='noreferrer' target='_blank'>
                  <FontAwesomeIcon size='2x' icon={faArrowUpFromBracket} />
                </a>
              </td>
              <td className={styles.NavToolsText}>
                <a href={uploadUrl} rel='noreferrer' target='_blank'>
                  Client Upload
                </a>
              </td>
            </tr>
            <tr className={styles.NavToolsItem}>
              <td className={styles.NavToolsIcon}>
                <a href={payUrl} rel='noreferrer' target='_blank'>
                  <FontAwesomeIcon size='2x' icon={faDollarSign} />
                </a>
              </td>
              <td className={styles.NavToolsText}>
                <a href={payUrl} rel='noreferrer' target='_blank'>
                  Pay my PFS bill
                </a>
              </td>
            </tr>
            <tr className={styles.NavToolsItem}>
              <td className={styles.NavToolsIcon}>
                <a href={legalPayUrl} rel='noreferrer' target='_blank'>
                  <FontAwesomeIcon size='2x' icon={faDollarSign} />
                </a>
              </td>
              <td className={styles.NavToolsText}>
                <a href={legalPayUrl} rel='noreferrer' target='_blank'>
                  Pay my PFS Law bill
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={styles.NavSection}>
        <div className={styles.NavSectionHeader}>Company</div>
        <ul className={styles.NavSectionList}>
          <li>
            <Link to={'/what-we-do'}>What We Do</Link>
          </li>
          <li>
            <Link to={'/who-we-are'}>Who We Are</Link>
          </li>
          <li>
            <Link to={'/articles'}>What We Think</Link>
          </li>
          <li>
            <Link to={'/acquisitions'}>Acquisitions</Link>
          </li>
        </ul>
      </div>
      <div className={styles.NavSection}>
        <div className={styles.NavSectionHeader}>Get in Touch</div>
        <ul className={styles.NavSectionList}>
          <li>
            <Link to={'/contact'}>Contact Us</Link>
          </li>
          <li>
            <Link to={'/careers'}>Careers</Link>
          </li>
        </ul>
      </div>
      <div className={styles.NavSection}>
        <div className={styles.NavSectionHeader}>Follow Us</div>
        <div id={styles.NavIconSection}>
          <table>
            <tbody>
              <tr>
                <td>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://www.facebook.com/MyAgencyPoint/'
                  >
                    <FontAwesomeIcon size='2x' icon={faFacebookF} />
                  </a>
                </td>
                <td>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://instagram.com/myagencypoint'
                  >
                    <FontAwesomeIcon size='2x' icon={faInstagram} />
                  </a>
                </td>
                <td>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://www.linkedin.com/company/myagencypoint/'
                  >
                    <FontAwesomeIcon size='2x' icon={faTwitter} />
                  </a>
                </td>
                <td>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://twitter.com/MyAgencyPoint'
                  >
                    <FontAwesomeIcon size='2x' icon={faLinkedinIn} />
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://www.youtube.com/channel/UCf3jPS8lu2v-wUjNy6bnD8w'
                  >
                    <FontAwesomeIcon size='2x' icon={faYoutube} />
                  </a>
                </td>
                <td>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://proadvisor.intuit.com/app/accountant/search?searchId=aaron-c-stocks'
                  >
                    <QBIcon />
                  </a>
                </td>
                <td>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://www.yelp.com/biz/pfs-global-diamond-bar'
                  >
                    <FontAwesomeIcon size='2x' icon={faYelp} />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ContentContainer>
  </div>
);

PFSFooterNav.propTypes = {};

PFSFooterNav.defaultProps = {};

export default PFSFooterNav;
