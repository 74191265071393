import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PFS404Page from '../PFS404Page/PFS404Page';
import PFSAcquisitionsIndex from '../PFSAcquisitionsIndex/PFSAcquisitionsIndex';
import PFSYorke from '../PFSYorke/PFSYorke';
import PFSMedlock from '../PFSMedlock/PFSMedlock';

const PFSAcquisitionRoutes = () => (
  <Routes>
    <Route path='/' element={<PFSAcquisitionsIndex />} />
    <Route path='/yorke' element={<PFSYorke />} />
    <Route path='/medlock' element={<PFSMedlock />} />
    {/* <Route path='/premier' element={<PFSPremier />} /> */}
    <Route path='*' element={<PFS404Page />} />
  </Routes>
);

PFSAcquisitionRoutes.propTypes = {};

PFSAcquisitionRoutes.defaultProps = {};

export default PFSAcquisitionRoutes;
