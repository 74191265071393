import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ContentContainer from '../ContentContainer/ContentContainer';
import styles from './PFSContactCta.module.css';

const PFSContactCta = (props) => {
  const { metaText, ctaText } = props;

  return (
    <ContentContainer>
      <div className={styles.PFSContactCta} data-testid='PFSContactCta'>
        <div className={styles.CTAText}>
          <p data-testid='PFSMetaText'>{metaText}</p>
          <p className={styles.CTABold} data-testid='PFSCtaText'>
            {ctaText}
          </p>
        </div>
        <div className={styles.CTAButtons}>
          <Link to={'/contact'}>Contact Us</Link>
          <a href='tel:19092947372'>909.294.7372</a>
        </div>
      </div>
    </ContentContainer>
  );
};

PFSContactCta.propTypes = {
  ctaText: PropTypes.string.isRequired,
  metaText: PropTypes.string,
};

PFSContactCta.defaultProps = {
  metaText: '',
  ctaText: undefined,
};

export default PFSContactCta;
