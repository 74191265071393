import React, { useContext, useMemo } from 'react';
import styles from './PFSWhoWeAreCollage.module.css';
import ContentContainer from '../ContentContainer/ContentContainer';
import LoadingImage from '../LoadingImage/LoadingImage';
import WhoWeAreContext from '../../contexts/WhoWeAreContext';

const PFSWhoWeAreCollage = () => {
  const { loading, staffData, onCollageStaffClick } = useContext(WhoWeAreContext);

  const filteredStaff = useMemo(() => {
    // const departmentsToIgnore = ['InsurancePartner'];

    return staffData.filter((val) => {
      const hasImage = val?.imageURL;
      // const shouldIgnore = departmentsToIgnore.includes(val?.department);

      return hasImage;
      // return (hasImage && !shouldIgnore);
    });
  }, [staffData]);

  const renderImages = () => {
    return filteredStaff.map((staff, index) => (
      <LoadingImage
        image={staff.imageURL}
        altText={`Staff Member ${index + 1}`}
        key={index}
        titleText={`${staff.name}\n${staff.title}`}
        onClickFunc={() => onCollageStaffClick(staff)}
      />
    ));
  };

  return (
    <div className={styles.PfsWhoWeAreCollage} data-testid="PfsWhoWeAreCollage">
      <ContentContainer>
        {loading ? <LoadingImage altText='Loading Collage' /> : (
          <div className={styles.StaffImages}>
            {renderImages()}
          </div>
        )}
      </ContentContainer>
    </div>
  );
};

export default PFSWhoWeAreCollage;
