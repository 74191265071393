import React from 'react';
import PropTypes from 'prop-types';

const QBIcon = (props) => {
  // Generated from Adobe XD file and converted to JSX with:
  // https://svg2jsx.com/
  return(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 36.294 32.945"
      data-testid='QBIcon'
      fontSize={'2em'}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M29.12 25.1v13.107h4.113c5.661 0 7.415-.487 9.536-2.627 6.395-6.452 2.24-16.873-6.734-16.891h-1.311V22.3H35.8c4.717 0 7.731 4.152 6.127 8.44-.984 2.631-2.9 3.707-6.8 3.819l-2.4.069V24.569c0-11.831.055-11.518-2.156-12.3-1.62-.572-1.46-1.985-1.46 12.83m-12.358-6.13C5.769 21.631 7.964 38.181 19.313 38.2h1.311v-3.609h-1.076c-3.818 0-6.517-2.546-6.517-6.146 0-3.991 2.634-6.146 7.511-6.146h2.07v10.04c0 10.914-.013 10.761 1.02 11.68a4.607 4.607 0 002.168.881h.43V18.684l-4.2.015a29.387 29.387 0 00-5.263.272"
        transform="translate(-9.565 -11.95)"
      >
      </path>
    </svg>
  )
};

QBIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

QBIcon.defaultProps = {
  height: "1em",
  width: "1em",
};

export default QBIcon;
