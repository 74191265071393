import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './PFSMeetTheTeam.module.css';
import ContentContainer from '../ContentContainer/ContentContainer';
import AccordionSection from '../AccordionSection/AccordionSection';
import PFSStaffMember from '../PFSStaffMember/PFSStaffMember';
import { AdminSvg } from '../PFSWhoWeAreContent/Svg';
import { getStaffData } from '../../lib/GetStaffData';
import AccordionTitle from '../AccordionTitle/AccordionTitle';

const PFSMeetTheTeam = (props) => {
  const {
    department,
    title,
    icon,
    primaryColor,
    secondaryColor,
    isOpenStart,
    className,
    closedClassName,
    contentClassName,
  } = props;

  const [staff, setStaff] = useState([]);
  const [showSection, setShowSection] = useState(isOpenStart);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getStaffData(department)
      .then((response) => {
        setStaff(response);
      })
      .catch((e) => {
        setStaff([]); // TODO: Replace this with error image
      })
      .finally(() => {
        setLoading(false);
      });
  }, [department]);

  const iconFillSettings = {
    isFilled: showSection,
    isFilledColor: secondaryColor.color,
    notFilledColor: primaryColor.color,
  };

  return (
    <ContentContainer>
      <div className={styles.PFSMeetTheTeam} data-testid='PFSMeetTheTeam'>
        <AccordionSection
          loading={loading}
          isOpen={showSection}
          title={
            <AccordionTitle
              title={title}
              Icon={icon}
              iconFill={iconFillSettings}
            />
          }
          onToggle={() => setShowSection(!showSection)}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          className={className}
          closedClassName={closedClassName}
          contentClassName={contentClassName}
        >
          <div data-testid='PFSMeetTheTeamChildren'>
            <center>
              <TeamChildren staff={staff} />
            </center>
          </div>
        </AccordionSection>
      </div>
    </ContentContainer>
  );
};

const accordionColorShape = PropTypes.shape({
  color: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
});

PFSMeetTheTeam.propTypes = {
  department: PropTypes.string.isRequired,
  title: PropTypes.string,
  icon: PropTypes.elementType,
  primaryColor: accordionColorShape,
  secondaryColor: accordionColorShape,
  isOpenStart: PropTypes.bool,
  className: PropTypes.string,
  closedClassName: PropTypes.string,
  contentClassName: PropTypes.string,
};

PFSMeetTheTeam.defaultProps = {
  department: undefined,
  title: 'Meet the Team',
  icon: AdminSvg,
  primaryColor: {
    color: '#1775a9',
    backgroundColor: '#fff',
  },
  secondaryColor: {
    color: '#fff',
    backgroundColor: '#153459',
  },
  isOpenStart: true,
  className: '',
  closedClassName: '',
  contentClassName: '',
};

export default PFSMeetTheTeam;

export const TeamChildren = ({ staff }) => {
  return staff.map((staffMember, index) => (
    <PFSStaffMember
      key={index}
      name={staffMember.name}
      title={staffMember.title}
      image={staffMember.imageURL}
      nameClassName={'StaffName'}
      titleClassName={'StaffTitle'}
    />
  ));
};
