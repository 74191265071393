import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PFSServicesRedirect = () => {
  const nav = useNavigate()

  useEffect(() => {
    nav('/what-we-do')
  }, [nav])

  return(<></>)
};

export default PFSServicesRedirect;
