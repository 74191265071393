import React from 'react';
import PropTypes from 'prop-types';
import styles from './PFSArticleCard.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import placeholderImage from './empty.png';
import LoadingImage from '../LoadingImage/LoadingImage';

const PFSArticleCard = (props) => {
  const { image, title, summary, publishedAt, articleSlug } = props;
  return (
    <div className={styles.PfsArticleCard} data-testid='PfsArticleCard'>
      <Link to={`/articles/${articleSlug ?? ''}`}>
        <LoadingImage
          placeholderImage={placeholderImage}
          image={image}
          styleclass={styles.ArticleImage}
          altText='Article'
        />
      </Link>
      <div>
        <p className={styles.ArticleTitle} data-testid='PFSArticleCardTitle'>
          <Link to={`/articles/${articleSlug ?? ''}`}>{title}</Link>
        </p>
        <p
          className={styles.ArticleSummary}
          data-testid='PFSArticleCardSummary'
        >
          {summary}
        </p>
        <div className={styles.ArticleInfo}>
          <div data-testid='PFSArticleCardPublishedDate'>
            <FontAwesomeIcon className={styles.icon} icon={faCalendarDays} />
            {publishedAt ? new Date(publishedAt).toUTCString().slice(0, -13) : '-'}
          </div>
        </div>
      </div>
    </div>
  );
};

PFSArticleCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  publishedAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  articleSlug: PropTypes.string.isRequired,
};

PFSArticleCard.defaultProps = {
  image: undefined,
  title: undefined,
  summary: undefined,
  publishedAt: undefined,
  articleSlug: undefined,
};

export default PFSArticleCard;
