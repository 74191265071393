import React from 'react';
import styles from './PFSAscend.module.css';
import ContentContainer from '../ContentContainer/ContentContainer';
import ascendLogo from './Ascend_logo.png';
import { NavLink, useLocation } from 'react-router-dom';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { PartnersIndexBreadcrumb } from '../PFSPartnersIndex/PFSPartnersIndex';

const PFSAscend = () => {
  const { pathname } = useLocation();

  useBreadcrumb([
    PartnersIndexBreadcrumb,
    {
      label: 'Ascend',
      url: pathname,
    },
  ]);

  return (
    <div className={styles.PfsAscend} data-testid="PfsAscend">
      <ContentContainer>
        <img className={styles.logo} src={ascendLogo} alt="Ascend" />
        <h1>Ascend Joins PFS Global as a Partner!</h1>
        <div className={styles.content}>
          <p>
            We are pleased to announce that Ascend has become a partner of PFS Global. 
            Ascend was established in 2021 and is based in San Francisco.
          </p>
          <p>Purpose built for insurance, Ascend provides an all-in-one solution to unify insurance
            billing, payments and accounting tasks to improve revenue, margins,
            and the customer experience.
          </p>
          <p>
            This collaboration allows us to integrate Ascend's advanced
            technology and expertise into our offerings, ensuring that we
            deliver enhanced value and efficiency to you, our esteemed clients.
          </p>
          <p>
            For more information about Ascend, please visit{' '}
            <a href="http://www.useascend.com">www.useascend.com</a>, or reach
            out directly at 415-404-2934 or{' '}
            <a href="mailto:hello@useascend.com">hello@useascend.com</a>.
          </p>
          <p>
            We are excited about the opportunities this partnership presents and
            are available to address any inquiries or feedback you may have.
          </p>
          <NavLink to={'/'} id={styles.homeBtn}>
            Continue
          </NavLink>
        </div>
      </ContentContainer>
    </div>
  );
};

export default PFSAscend;
