/**
 * Checks whether the `string` contains a partial match with the `query` by 
 * comparing the characters in the `query` with the characters in the `string` 
 * in the same order, but not necessarily consecutively. 
 * 
 * The search is case-insensitive. 
 */
export const fuzzySearch = (string, query) => {
  const lowerCaseString = string.toLowerCase();
  const lowerCaseQuery = query.toLowerCase();
  let index = 0;
  let lastIndex = -1;
  let letter;
  for (; (letter = lowerCaseQuery[index++]); ) {
    lastIndex = lowerCaseString.indexOf(letter, lastIndex + 1);
    if (lastIndex === -1) {
      return false;
    }
  }
  return true;
};
