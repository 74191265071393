import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PFS404Page from '../PFS404Page/PFS404Page';
import PFSPartnersIndex from '../PFSPartnersIndex/PFSPartnersIndex';
import PFSAscend from '../PFSAscend/PFSAscend';

const PFSPartnersRoutes = () => (
  <Routes>
    <Route path="/" element={<PFSPartnersIndex />} />
    <Route path="/ascend" element={<PFSAscend />} />
    <Route path="*" element={<PFS404Page />} />
  </Routes>
);

PFSPartnersRoutes.propTypes = {};

PFSPartnersRoutes.defaultProps = {};

export default PFSPartnersRoutes;
