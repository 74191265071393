import { notification } from 'antd';
import React, { useCallback, useState } from 'react';
import submitCareersForm from '../../lib/CareersFormPost';
import styles from './PFSCareersForm.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';

const PFSCareersForm = () => {
  const [formData, setFormData] = useState({
    fName: '',
    lName: '',
    phone: '',
    email: '',
    message: '',
    position: '',
    resume: '',
  });
  const { fName, lName, email, phone, message, resume, position } = formData;
  const [isDisabled, setDisabled] = useState(false);

  const handleValueChange = (ev) => {
    let input = ev.target;
    setFormData({ ...formData, [input.name]: input.value });
  };

  const captchaCode = async () => {
    let newCode = await window.grecaptcha.execute(
      process.env.REACT_APP_CAPTCHA_KEY,
      { action: 'careers_form' }
    );
    return newCode;
  };

  const handleSuccess = useCallback(() => {
    notification.success({
      message: 'Your information has been sent!',
      description:
        'Thank you for your applying! We will reach out to you as soon as possible.',
    });
  }, []);

  const handleFailure = useCallback((messages) => {
    messages.forEach((message) => {
      notification.error({
        message: 'Oops! There was a problem',
        description: message,
      });
    });
  }, []);

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setDisabled(true);
    let careersResponse;
    try {
      const captcha = await captchaCode();
      const careersBody = new FormData();

      for (let key in formData) {
        careersBody.append(key, formData[key]);
      }
      careersBody.append('captcha', captcha);
      careersResponse = await submitCareersForm(careersBody);
      if (careersResponse.data.success) {
        handleSuccess();
      } else {
        handleFailure(careersResponse.data.errors);
      }
    } catch (e) {
      handleFailure([
        'Looks like there was a problem. Please reach out to us over email instead',
      ]);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <div className={styles.PFSCareersForm} data-testid='PFSCareersForm'>
      <div id={styles.FormContainer}>
        <form data-testid='CareersFormElement' onSubmit={handleSubmit}>
          <div className={styles.NameInputs}>
            <input
              disabled={isDisabled}
              type='text'
              name='fName'
              placeholder='First Name'
              value={fName}
              onChange={handleValueChange}
            />
          </div>
          <div className={styles.NameInputs}>
            <input
              disabled={isDisabled}
              type='text'
              name='lName'
              placeholder='Last Name'
              value={lName}
              onChange={handleValueChange}
            />
          </div>
          <input
            disabled={isDisabled}
            type='tel'
            name='phone'
            placeholder='Phone'
            value={phone}
            onChange={handleValueChange}
          />
          <input
            disabled={isDisabled}
            type='email'
            name='email'
            placeholder='Email'
            value={email}
            onChange={handleValueChange}
          />
          <input
            disabled={isDisabled}
            type='text'
            name='position'
            placeholder='Position'
            value={position}
            onChange={handleValueChange}
          />
          <textarea
            disabled={isDisabled}
            name='message'
            placeholder='Message'
            value={message}
            onChange={handleValueChange}
          />
          <div className={styles.buttonsContainer}>
            <label htmlFor='resume'>
              <FontAwesomeIcon icon={faPaperclip} />
              {resume ? resume.name.substring(0, 14) : 'Upload Resume'}
            </label>
            <input
              type='file'
              hidden
              id='resume'
              data-testid='resume'
              accept='application/msword,  application/pdf, image/*'
              onChange={(e) => {
                setFormData({ ...formData, resume: e.target.files[0] });
              }}
            />
            <input
              disabled={isDisabled}
              type='submit'
              value={isDisabled ? 'Sending...' : 'Submit Resume'}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

PFSCareersForm.propTypes = {};

PFSCareersForm.defaultProps = {};

export default PFSCareersForm;
