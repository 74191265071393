import {
  PayrollSvg,
  AdminSvg,
  HandshakingSvg,
  BookKeepingSvg,
  LawSvg,
  TaxSvg,
  HCMSvg,
  AdvisorsSvg,
} from '../PFSWhoWeAreContent/Svg';

// Accordion sections
export const sections = [
  {
    Icon: AdminSvg,
    title: 'Administration Team',
    department: 'Admin',
  },
  {
    Icon: HandshakingSvg,
    title: 'Industry Partners',
    department: 'IndustryPartner',
  },
  {
    Icon: BookKeepingSvg,
    title: 'Bookkeeping Team',
    department: 'Bookkeeping',
  },
  {
    Icon: TaxSvg,
    title: 'Tax Team',
    department: 'Tax',
  },
  {
    Icon: PayrollSvg,
    title: 'Payroll Team',
    department: 'Payroll',
  },
  {
    Icon: LawSvg,
    title: 'PFS Law, APC Team',
    department: 'Legal',
  },
  {
    Icon: HCMSvg,
    title: 'Human Capital Management Team',
    department: 'HCM',
  },
  {
    Icon: AdvisorsSvg,
    title: 'Advisory Team',
    department: 'Advisor',
  },
];
