import React from 'react';
import styles from './PFSAcquisitionsIndex.module.css';
import ContentContainer from '../ContentContainer/ContentContainer';
import PFSServiceCard from '../PFSServiceCard/PFSServiceCard';

import yorkeSvg from '../PFSYorke/yorke_cpa.svg'
import patrikMedlockLogo from "../PFSMedlock/patrick_medlock.png";
import { useBreadcrumb } from '../../hooks/useBreadcrumb';

export const AcquisitionsIndexBreadcrumb = {
  label: 'Acquisitions',
  url: '/acquisitions'
}

const PFSAcquisitionsIndex = () => {
  useBreadcrumb([AcquisitionsIndexBreadcrumb])

  return (
    <div className={styles.PfsAcquisitionsIndex} data-testid="PFSAcquisitionsIndex">
      <ContentContainer>
        <center><h1>PFS Global: Acquisitions</h1></center>
        <center><p>We are proud of our growth and this is just the beginning. Please contact us if you're interested in partnering or being acquired by PFS.</p></center>
        <br /><br /><br />
        <section className={styles.ServiceCardSection}>
          <div className={styles.ServiceCardContainer}>
            <PFSServiceCard
              icon={
                <img
                  alt='Yorke & Associates Logo'
                  src={yorkeSvg}
                  width='128em'
                />
              }
              subtitle="Tax"
              title={['Yorke & Associates,', 'CPAs']}
              url='/acquisitions/yorke'
            />
          </div>
          <div className={styles.ServiceCardContainer}>
            <PFSServiceCard
              icon={<img
                alt='Patrick W. Medlock, CPA'
                src={patrikMedlockLogo}
                width='188em'
              />}
              subtitle="Tax"
              title={["Patrick W. Medlock", "CPA"]}
              url='/acquisitions/medlock'
            />
          </div>
        </section>

        <br /><br /><br /><br /><br />
      </ContentContainer>
    </div>
  )
};

PFSAcquisitionsIndex.propTypes = {};

PFSAcquisitionsIndex.defaultProps = {};

export default PFSAcquisitionsIndex;
