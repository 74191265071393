import React from 'react';
import styles from './PFSPrivacyPolicy.module.css';
import globalStyles from '../../styles/global.module.css';
import ContentContainer from '../ContentContainer/ContentContainer';
import { Link } from 'react-router-dom';
const PFSPrivacyPolicy = () => (
  <div className={styles.PFSPrivacyPolicy} data-testid="PFSPrivacyPolicy">
    <ContentContainer>
      <p className={globalStyles.TitleText}>Privacy Policy</p>
      <em>
        Last Updated: <b>August 28, 2024</b>
      </em>

      <h3>1. Introduction</h3>
      <p>
        PFS Global, LLC ("we," "us," or "our") iscommitted to protecting your
        privacy. ThisPrivacy Policyoutlineshow we collect, use, protect, and
        share your PersonallyIdentifiable Information (PII) when you visit our
        website www.pfsonline.com or use our services, including SMS
        communications.
      </p>

      <h3>2. Information We Collect</h3>
      <p>
        We collect several typesof information from and about usersof our
        website, including:
      </p>
      <ul>
        <li>
          <b>Personal Information:</b> Identifiable information such asname,
          postal address, email address, telephone number, or anyother
          identifier bywhich you maybe contacted online or offline.
        </li>

        <li>
          <b>Usage Data:</b> Information about your internet connection, the
          equipment you use to accessour website, and usage details.
        </li>
        <li>
          <b>Cookies and Tracking Technologies:</b> We use cookiesand similar
          technologiesto understand how you use our website and to improve your
          experience.
        </li>
      </ul>

      <h3>3. How we use your information</h3>
      <p>We use the information we collect for the following purposes:</p>
      <ul>
        <li>To process your transactions quickly</li>

        <li>To provide, maintain, and improve our website and services.</li>
        <li>To understand your usage patterns and improve user experience.</li>
        <li>
          To communicate with you about our services, offers, and updates.
        </li>
      </ul>

      <h3>3. Mobile Subscriber Information</h3>
      <p>
        <b>
          Your Mobile Information will NEVER be shared with other parties under
          any circumstances.
        </b>{' '}
        This includes no sharing with third parties, affiliates, or for
        marketing or promotional purposes.
      </p>

      <h3>5. SMS Communications</h3>
      <p>
        We use SMS messaging to communicate with you about your account,
        transactions, or other services.
      </p>
      <ul>
        <li>
          <b>Opt-In:</b> You must opt-in for SMS communications by replying
          "YES" to an initial message or another method provided during sign-up.
        </li>

        <li>
          <b>Opt-Out: </b> You may opt out at any time by replying "STOP" to any
          text message from us.
        </li>
        <li>
          <b>Help:</b> For assistance, reply "HELP" to any message or contact us
          directly
        </li>
      </ul>

      <h3>6. Data Sharing and Disclosure</h3>
      <p>
        We do not sell, trade, or otherwise transfer your PII to outside parties
        without prior notice, except as necessary to provide our services or
        comply with the law.
      </p>

      <h3>7. Cookies and Tracking</h3>
      <p>
        Our website does not use cookies for tracking purposes. You can manage
        cookies through your browser settings.
      </p>

      <h3>8. Third-Party Links</h3>
      <p>
        We do not include or offer third-party products or services on our
        website.
      </p>

      <h3>9. Compliance with Laws</h3>
      <ul>
        <li>
          <b>California Residents: Your Privacy Rights</b>
          <p>
            Under the CCPA and CPRA, California residents have specific rights
            regarding their personal information:
          </p>
          <ul>
            <li>
              <b>Right to Know:</b> Request information about the personal
              information we collect, use, disclose, and sell.
            </li>
            <li>
              <b>Right to Delete:</b> Request deletion of your personal
              information, subject to certain exceptions.
            </li>
            <li>
              <b>Right to Opt-Out of Sale:</b> If we sell your personal
              information, you have the right to opt-out.
            </li>
            <li>
              <b>Right to Non-Discrimination:</b> You will not be discriminated
              against for exercising your CCPA rights.
            </li>
          </ul>
        </li>

        <li>
          <p>
            To exercise these rights, please <Link to={'/contact'}>contact us</Link>
          </p>
          <ul>
            <li>
              <b>Do Not Sell My Personal Information</b>
              <p>
                To opt out of the sale of personal information, please <Link to={'/contact'}>contact us</Link>
              </p>
            </li>
          </ul>
        </li>
        <li>
          <b>California Online Privacy Protection Act (CalOPPA)</b>
          <p>
            Users can visit our site anonymously. Our Privacy Policy link is
            easily accessible from our homepage.
          </p>
        </li>
        <li>
          <b>Children's Online Privacy Protection Act (COPPA)</b>
          <p>We do not market to children under 13 years of age.</p>
        </li>
        <li>
          <b>CAN-SPAM Act</b>
          <p>
            We comply with the CAN-SPAM Act, providing opt-out options in all
            communications
          </p>
        </li>
      </ul>
      <h3>10. Fair Information Practices</h3>
      <p>
        We will notify you via email within 7 business days if a data breach
        occurs. We adhere to the Individual Redress Principle, allowing
        individuals to pursue enforceable rights against data collectors.
      </p>

      <h3>11. Your Rights</h3>
      <p>
        You have the right to access, update, or delete your personal
        information. <Link to={'/contact'}>contact us</Link> to exercise these
        rights.
      </p>

      <h3>12. Data Retention</h3>
      <p>
        We retain your personal information only as long as necessary for the
        purposes for which it was collected, including legal, accounting, or
        reporting requirements.
      </p>

      <h3>13. Changes to Our Privacy Policy</h3>
      <p>
        We may update this Privacy Policy periodically. Any changes will be
        posted on this page with an updated effective date.
      </p>

      <h3>14. Contact Information</h3>
      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at:
      </p>
      <ul>
        <li>
          <b>Email:</b> Info@pfsonline.com
        </li>
        <li>
          <b>Phone:</b> 909.294.7372
        </li>
        <li>
          <b>Address:</b> 556 N. Diamond Bar Blvd, #101, Diamond Bar, CA 91765
        </li>
      </ul>

      <h3>15. Text Messages</h3>
      <p>
        By opting in, you authorize us to send SMS communications. Message
        frequency varies, and message and data rates may apply. Reply "STOP" to
        opt out or "HELP" for assistance.
      </p>

      <h3>16. Examples of SMS Messages</h3>
      <ul>
        <li>
          <b>Opt-In Message:</b> "Hi, this is Aaron from PFS Global. Would you
          prefer to communicate by text (SMS)? If so, reply YES. Reply STOP to
          no longer receive SMS messages from us."
        </li>

        <li>
          <b>Opt-Out Message:</b> "Got it! You will no longer receive SMS
          messages from us."
        </li>
        <li>
          <b>Help Message:</b> "Hi, if you're encountering any issues or have
          questions, please call us at 909.294.7372. Reply STOP to opt out."
        </li>
      </ul>
      <br />
      <br />
      <br />
      <br />
      <br />
    </ContentContainer>
  </div>
);
PFSPrivacyPolicy.propTypes = {};
PFSPrivacyPolicy.defaultProps = {};
export default PFSPrivacyPolicy;
