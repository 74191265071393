import React from 'react';
import styles from './HeaderCallUs.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadphonesSimple } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

const HeaderCallUs = () => (
  <div className={styles.HeaderCallUs} data-testid="HeaderCallUs">
    <div id={styles.TextContainer} data-testid={'TextContainer'}>
      <span id={styles.CallUsText}>Call Us</span>
      <br></br>
      <span id={styles.CallUsPhoneNumber}>909.294.7372</span>
    </div>

    <div id={styles.IconContainer} data-testid={'IconContainer'}>
      <FontAwesomeIcon size='2x' icon={faHeadphonesSimple}></FontAwesomeIcon>
    </div>

    <NavLink to={'/contact'} id={styles.ContactBtn}>
      Contact Us
    </NavLink>
  </div>
);

HeaderCallUs.propTypes = {};

HeaderCallUs.defaultProps = {};

export default HeaderCallUs;
