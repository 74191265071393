import { faShield } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import BaseReason from './BaseReason';

const ReasonIntegrity = () => {
    return (
        <BaseReason
            icon={<FontAwesomeIcon icon={faShield} size='4x' />}
            title='Integrity'
            content={[
                "At PFS, we believe in treating our clients with honesty, professionalism, and respect.",
                "We understand that your business relies on accurate information, and we take our responsibility to provide that seriously.",
                "When you choose PFS, you can trust that we will always act with integrity and put your best interests first."
            ]}
        />
    )
}

export default ReasonIntegrity