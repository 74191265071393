import { createContext } from "react";

const WhoWeAreContext = createContext({
    loading: true,
    staffData: [],
    currSection: '',
    setCurrSection: () => {},
    onCollageStaffClick: () => {},
    staffNameToId: () => {}
});

export default WhoWeAreContext;