export const CacheTimeoutInHours = 2;

export const getHoursSinceCached = (cachedAt) =>
  (new Date().getTime() - cachedAt) / 3600000;

const localStorageAccessDenied = () => {
  return localStorage === null;
};

export const loadFromCache = (key) => {
  if (localStorageAccessDenied()) {
    return false;
  }
  const cachedData = localStorage.getItem(key);

  if (!cachedData) {
    return false;
  }

  const { cachedAt, data } = JSON.parse(cachedData);
  const cacheExpired = getHoursSinceCached(cachedAt) > CacheTimeoutInHours;

  return cacheExpired ? false : data;
};

export const storeInCache = (key, data) => {
  if (localStorageAccessDenied()) {
    return;
  }
  const rightNow = new Date().getTime();

  localStorage.setItem(
    key,
    JSON.stringify({
      data,
      cachedAt: rightNow,
    })
  );
};
