import React, { useContext } from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import styles from './PFSBreadCrumb.module.css';
import BreadcrumbContext from '../../contexts/BreadcrumbContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const BreadcrumbLink = ({ url, label }) => (
  <Link data-testid="Bread-Crumb-Link" to={url}>
    {label}
  </Link>
)

export const HomeLinkIcon = () => (
  <FontAwesomeIcon data-testid="Home-page-icon" icon={faHome} />
)

const PFSBreadCrumb = () => {
  const { breadcrumbItems } = useContext(BreadcrumbContext);
  const allLinks = [
    {
      label: <HomeLinkIcon />,
      url: '/'
    },
    ...breadcrumbItems
  ];

  return (
    breadcrumbItems.length > 0 && (
      <Breadcrumb className={styles.PFSBreadCrumb} data-testid="PFSBreadCrumb">
        {allLinks.map(({ label, url }, index) => (
          <Breadcrumb.Item key={index}>
            <BreadcrumbLink label={label} url={url} />
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    )
  );
};

export default PFSBreadCrumb;
