import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './PFSArticleList.module.css';
import PFSArticleCard from '../PFSArticleCard/PFSArticleCard';

const PFSArticleList = (props) => {
  const {
    loading,
    list,
    title,
    canLoadMore,
    loadMoreText = 'Show More Articles',
    onLoadMore,
  } = props;

  const articles = useMemo(
    () =>
      list?.map((blog, index) => (
        <PFSArticleCard
          key={blog.slug}
          image={blog.bannerImage}
          title={blog.title}
          summary={blog.summary}
          publishedAt={blog.publishedAt}
          articleSlug={blog.slug}
        />
      )),
    [list]
  );

  return (
    <div className={styles.PfsArticleList} data-testid='PfsArticleList'>
        <p className={styles.HeadingWithLine}>
          <b data-testid='PFSArticleListTitle' className={styles.MarginRight}>
            {title}
          </b>
          articles
        </p>
        {loading ? (
          <div data-testid="ArticlesLoading" className={styles.Loader}></div>
        ) : (
          <>
            {articles}
            {canLoadMore && (
              <center>
                <button
                  data-testid='PFSArticleListLoadButton'
                  className={styles.loadMoreButton}
                  onClick={onLoadMore}
                >
                  {loadMoreText}
                </button>
              </center>
            )}
          </>
        )}
    </div>
  );
};

const blogShape = PropTypes.shape({
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  publishedAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  bannerImage: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
});

PFSArticleList.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(blogShape),
  canLoadMore: PropTypes.bool.isRequired,
  loadMoreText: PropTypes.string,
  onLoadMore: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default PFSArticleList;
