import React from 'react';
import PropTypes from 'prop-types';
import styles from './SpotlightArticleCard.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import LoadingImage from '../LoadingImage/LoadingImage';
import emptyImage from '../ArticleBanner/empty.png';
import { Link } from 'react-router-dom';

const SpotlightArticleCard = (props) => {
  const { slug, title, publishedAt, bannerImage } = props;
  return (
    <div
      className={styles.SpotlightArticleCard}
      data-testid='SpotlightArticleCard'
    >
      <Link to={`/articles/${slug ?? ''}`}>
        <LoadingImage
          placeholderImage={emptyImage}
          image={bannerImage ?? ''}
          styleclass={styles.SpotlightImage}
          altText='ARTICLE'
        />
        <div className={styles.text}>
          <p data-testid='SpotlightTitle' className={styles.ArticleTitle}>
            {title}
          </p>

          <div className={styles.Info}>
            <div data-testid='SpotlightPublishedDate'>
              <FontAwesomeIcon className={styles.icon} icon={faCalendarDays} />
              {publishedAt ? new Date(publishedAt).toUTCString().slice(0, -13) : '-'}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

SpotlightArticleCard.propTypes = {
  title: PropTypes.string.isRequired,
  publishedAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  bannerImage: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

SpotlightArticleCard.defaultProps = {
  title: undefined,
  publishedAt: undefined,
  bannerImage: undefined,
  slug: undefined,
};

export default SpotlightArticleCard;
