import React, { useEffect, useState, useCallback, useContext } from 'react';
import styles from './PFSWhoWeAreContent.module.css';
import ContentContainer from '../ContentContainer/ContentContainer';
import AccordionSection from '../AccordionSection/AccordionSection';
import AccordionTitle from '../AccordionTitle/AccordionTitle';
import PFSStaffMember from '../PFSStaffMember/PFSStaffMember';
import { sections } from './Data';
import WhoWeAreContext from '../../contexts/WhoWeAreContext';

const accordianPrimaryColor = {
  color: '#1775a9',
  backgroundColor: '#fff',
};

const accordianSecondaryColor = {
  color: '#fff',
  backgroundColor: '#153459',
};

const PFSWhoWeAreContent = () => {
  const {
    loading,
    staffData,
    currSection,
    setCurrSection,
    staffNameToId
  } = useContext(WhoWeAreContext);

  const [data, setData] = useState({});
  const groupBy = useCallback((arr, keyFn) => {
    let groupedData = {};

    for (let i = 0; i < arr.length; i++) {
      let thisElem = arr[i];
      let thisKey = keyFn(thisElem);

      if (groupedData[thisKey] === undefined) {
        groupedData[thisKey] = [];
      }

      groupedData[thisKey].push(thisElem);
    }

    return groupedData;
  }, []);

  useEffect(() => {
    if (staffData) {
      let groupedData = groupBy(staffData, (val) => {
        return val.department;
      });
      setData(groupedData);
    }
  }, [staffData, groupBy]);

  const accordionTitle = (title, Icon, iconFill) => (
    <AccordionTitle
      title={title}
      Icon={Icon}
      iconFill={iconFill}
    />
  );

  const staffMemberComponent = useCallback((staffMember, idx) => (
    <PFSStaffMember
      id={staffNameToId(staffMember.name)}
      key={idx}
      name={staffMember.name}
      title={staffMember.title}
      image={staffMember.imageURL}
    />
  ), [staffNameToId]);

  const fillSettingsForCurrDept = useCallback((department) => ({
    isFilled: currSection === department,
    isFilledColor: '#fff',
    notFilledColor: '#1775a9'
  }), [currSection]);

  return (
    <ContentContainer>
      <div
        className={styles.PFSWhoWeAreContent}
        data-testid='PFSWhoWeAreContent'
      >
        {sections?.map((section, index) => (
          <AccordionSection
            autoScroll={true}
            isOpen={currSection === section.department}
            loading={loading}
            onToggle={() =>
              setCurrSection(currSection === section.department ? '' : section.department)
            }
            sectionId={section.department}
            title={accordionTitle(
              section.title,
              section.Icon,
              fillSettingsForCurrDept(section.department)
            )}
            primaryColor={accordianPrimaryColor}
            secondaryColor={accordianSecondaryColor}
            loaderColor={'#153459'}
            key={index}
          >
            <div data-testid='PFSWhoWeAreContentChildren'>
              <center>
                {data[section.department]?.map((staffMember, index) => (
                  staffMemberComponent(staffMember, index)
                ))}
              </center>
            </div>
          </AccordionSection>
        ))}
      </div>
    </ContentContainer>
  );
};

export default PFSWhoWeAreContent;
