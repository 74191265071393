import axios from 'axios';
import retryQuery from './RetryQuery';
import { loadFromCache, storeInCache } from '../helpers/CacheHelpers';

const sortByPublishedAt = (a, b) => {
  if (!a.publishedAt && !b.publishedAt) {
    return 0;
  }

  const aPublishedAt = new Date(a.publishedAt || 0).getTime();
  const bPublishedAt = new Date(b.publishedAt || 0).getTime();

  return bPublishedAt - aPublishedAt;
};

export const getBlogsData = async (slug) => {
  const key = slug ? `blog/${slug}` : 'blogs';
  let cachedData = loadFromCache(key);
  if (cachedData) {
    return cachedData;
  }

  try {
    if (localStorage) {
      localStorage.removeItem(key);
    }
    const { data, status } = await retryQuery(async () =>
      axios.get(`${process.env.REACT_APP_API_URL}/${key}`)
    );

    if (status === 200) {
      const sortedData = slug ? data : data.sort(sortByPublishedAt);
      storeInCache(key, sortedData);
      return sortedData;
    } else {
      return [];
    }
  } catch (error) {
    throw error;
  }
};
