import React from "react";
import styles from "./PFSMedlock.module.css";
import ContentContainer from "../ContentContainer/ContentContainer";
import { NavLink, useLocation } from "react-router-dom";

import patrikMedlockImage from "./patrick_medlock.png";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import { AcquisitionsIndexBreadcrumb } from "../PFSAcquisitionsIndex/PFSAcquisitionsIndex";

const PFSMedlock = () => {
  const { pathname } = useLocation();
  useBreadcrumb([
    AcquisitionsIndexBreadcrumb,
    {
      label: "Medlock",
      url: pathname,
    },
  ]);

  return (
    <div className={styles.PfsMedlock} data-testid="pfsMedlock">
      <ContentContainer>
        <img
          className={styles.logo}
          alt="Yorke & Associates Logo"
          src={patrikMedlockImage}
        />
        <div className={styles.content}>
          <h1>Patrick W. Medlock, CPA has merged with PFS Global!</h1>
          <p>Dear Clients, Friends, and Associates:</p>
          <p>
            After 33 years of practicing as a self-employed practitioner, I am
            happy to announce that I will be merging my practice with Aaron
            Stocks of PFS Global effective November 1, 2023.
          </p>
          <p>
            I have two primary goals for this merger. Of main importance, I want
            to increase the resources for client tax services and to provide the
            additional available services offered by PFS Global including
            bookkeeping, payroll, human capital management, legal, technology,
            and advisory. Secondarily, I want my clients to feel assured that
            there will always be a team in place for them.
          </p>
          <p>
            I will be moving my office to the following location on November 1,
            2023:
          </p>
          <div>PFS Global</div>
          <div>556 N. Diamond Bar Blvd. Suite 101</div>
          <div>Diamond Bar, CA 91765</div>
          <div>Office: 909.294.7372</div>
          <br />
          <p>
            As part of this transition, your files (i.e. tax returns,
            workpapers, software, etc.) will be transferred to and held by PFS
            Tax CPAs LLP. If you do not wish your files to be transferred to PFS
            Tax CPAs LLP, please notify me in writing before November 1, 2023 at
            patrick@pwmedlockcpa.com or 20955 Pathfinder Road, Ste 138, Diamond
            Bar, CA 91765.
          </p>
          <p>
            While change can be challenging, I truly believe that this move will
            be beneficial to you, and I will be there working hard to try and
            make this transition as smooth as possible. If you have any
            questions or concerns, please let me know.
          </p>
          <div>Sincerely,</div>
          <div>Patrick W. Medlock, CPA</div>
          <NavLink to={"/"} id={styles.homeBtn}>
            Continue
          </NavLink>
        </div>
      </ContentContainer>
    </div>
  );
};

export default PFSMedlock;
