import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import BreadcrumbContext from "../contexts/BreadcrumbContext";

export const useBreadcrumb = (links) => {
  const { setBreadcrumbItems } = useContext(BreadcrumbContext);
  const { pathname } = useLocation();

  useEffect(() => {
    setBreadcrumbItems(links);

    // Clean up when the component location changes
    return () => {
      setBreadcrumbItems([]);
    };
    // Re-render if the location changes OR the last page's label changes.
    // Adding more dependencies causes an infinite loop (re-rendering)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, links[links.length-1].label]);
};
