import React from 'react';
import PropTypes from 'prop-types';
import styles from './AuthorPanel.module.css';

const AuthorPanel = (props) => {
  const { author } = props;
  return (
    <div className={styles.AuthorPanel} data-testid='AuthorPanel'>
      <h1 className={styles.Header}>Author</h1>
      <p data-testid='AuthorName' className={styles.AuthorName}>
        {author}
      </p>
    </div>
  );
};

AuthorPanel.propTypes = {
  author: PropTypes.string.isRequired,
};

AuthorPanel.defaultProps = {
  author: undefined,
};

export default AuthorPanel;
