import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './PFSArticleCTA.module.css';
import ContentContainer from '../ContentContainer/ContentContainer';
import SpotlightArticleCard from '../SpotlightArticleCard/SpotlightArticleCard';
import SideArticleCard from '../SideArticleCard/SideArticleCard';
import { getBlogsData } from '../../lib/GetBlogData';
import { Link } from 'react-router-dom';
import { useRollbar } from '@rollbar/react';

const PFSArticleCta = ({ title, browseText }) => {
  const [blogs, setBlogs] = useState([]);
  const rollbar = useRollbar();

  useEffect(() => {
    getBlogsData()
      .then((response) => {
        setBlogs(response);
      })
      .catch((e) => {
        rollbar.error(e);
        setBlogs([]);
      });
  }, [rollbar]);

  return (
    <ContentContainer>
      {blogs.length > 0 && (
        <div className={styles.PfsArticleCta} data-testid='PfsArticleCta'>
          <div>
            <p className={styles.heading}>
              Latest
              <b> {title}</b>
            </p>
            <SpotlightArticleCard
              title={blogs[0]?.title}
              bannerImage={blogs[0]?.bannerImage}
              slug={blogs[0]?.slug}
              publishedAt={blogs[0]?.publishedAt}
            />
          </div>
          <div className={styles.Cards}>
            <Link className={styles.browseButton} to='/articles'>
              {browseText}
            </Link>
            <div className={styles.sideCards}>
              {blogs.slice(1, 7)?.map((blog) => (
                <SideArticleCard
                  key={blog.id}
                  image={blog.bannerImage}
                  title={blog.title}
                  summary={blog.summary}
                  publishedAt={blog.publishedAt}
                  articleSlug={blog.slug}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </ContentContainer>
  );
};

PFSArticleCta.propTypes = {
  title: PropTypes.string.isRequired,
  browseText: PropTypes.string.isRequired,
};

PFSArticleCta.defaultProps = {
  title: undefined,
  browseText: undefined,
};

export default PFSArticleCta;
