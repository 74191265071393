const currYear = new Date().getFullYear();

export const horizontalData = [
  {
    title: `${currYear} Tax Rates Married Individuals Filing Joint Returns and Surviving Spouses`,
    headers: ['If taxable income is over', 'But not over', 'The tax is'],
    rows: [
      ['$0', '$23,200', '10% of the taxable amount'],
      ['$23,200', '$94,300', '$2,320 plus 12% of the excess over $23,200'],
      ['$94,300', '$201,050', '$10,852 plus 22% of the excess over $94,300'],
      ['$201,050', '$383,900', '$34,337 plus 24% of the excess over $201,050'],
      ['$383,900', '$487,450', '$78,221 plus 32% of the excess over $383,9000'],
      ['$487,450', '$731,200', '$111,357 plus 35% of the excess over $487,450'],
      [
        'Over $731,200',
        'no limit',
        '$196,669.50 plus 37% of the excess over $731,200',
      ],
    ],
  },
  {
    title: `${currYear} Tax Rates Heads of Households`,
    headers: ['If taxable income is over', 'But not over', 'The tax is'],
    rows: [
      ['$0', '$16,550', '10% of the taxable amount'],
      ['$16,550', '$63,100', '$1,655 plus 12% of the excess over $16,550'],
      ['$63,100', '$100,500', '$7,241 plus 22% of the excess over $63,100'],
      ['$100,500', '$191,950', '$15,469 plus 24% of the excess over $100,500'],
      ['$191,950', '$243,700', '$37,417 plus 32% of the excess over $191,950'],
      ['$243,700', '$609,350', '$53,977 plus 35% of the excess over $243,700'],
      [
        '$609,350',
        'no limit',
        '$181,954.50 plus 37% of the excess over $609,350',
      ],
    ],
  },
  {
    title: `${currYear} Tax Rates Unmarried Individuals (other than Surviving Spouses and Heads of Households)`,
    headers: ['If taxable income is over', 'But not over', 'The tax is'],
    rows: [
      ['$0', '$11,600', '10% of the taxable amount'],
      ['$11,600', '$47,150', '$1,160 plus 12% of the excess over $11,600'],
      ['$47,150', '$100,525', '$5,426 plus 22% of the excess over $47,150'],
      [
        '$100,525',
        '$191,950',
        '$17,168.50 plus 24% of the excess over $100,525',
      ],
      [
        '$191,950',
        '$243,725',
        '$39,110.50 plus 32% of the excess over $191,950',
      ],
      [
        '$243,725',
        '$609,350',
        '$55,678.50 plus 35% of the excess over $243,725',
      ],
      [
        '$609,350',
        'no limit',
        '$183,647.25 plus 37% of the excess over $609,350',
      ],
    ],
  },
  {
    title: `${currYear} Tax Rates Married Individuals Filing Separate Returns`,
    headers: ['If taxable income is over', 'But not over', 'The tax is'],
    rows: [
      ['$0', '$11,600', '10% of the taxable amount'],
      ['$11,600', '$47,150', '$1,160 plus 12% of the excess over $11,600'],
      ['$47,150', '$100,525', '$5,426 plus 22% of the excess over $47,150'],
      [
        '$100,525',
        '$191,950',
        '$17,168.50 plus 24% of the excess over $100,525',
      ],
      [
        '$191,950',
        '$243,725',
        '$39,110.50 plus 32% of the excess over $191,950',
      ],
      [
        '$243,725',
        '$365,600',
        '$55,678.50 plus 35% of the excess over $243,725',
      ],
      [
        'Over $365,600',
        'no limit',
        '$98,334.75 plus 37% of the excess over $365,600',
      ],
    ],
  },
  {
    title: `${currYear} Tax Rates Estates and Trusts`,
    headers: ['If taxable income is over', 'But not over', 'The tax is'],
    rows: [
      ['$0', '$3,100', '10% of the taxable amount'],
      ['$3,100', '$11,150', '$310 plus 24% of the excess over $3,100'],
      ['$11,150', '$15,200', '$2,242 plus 35% of the excess over $11,150'],
      ['$15,200', 'no limit', '$3,659.50 plus 37% of the excess over $15,200'],
    ],
  }
];

export const verticalData = [
  {
    title: `Social Security ${currYear} Tax Rates`,
    rows: [
      ['Base Salary', '$168,600'],
      ['Social Security Tax Rate', '6.2%'],
      ['Maximum Social Security Tax', '$10,453.20'],
      ['Medicare Base Salary', 'unlimited'],
      ['Medicare Tax Rate', '1.45%'],
      ['Additional Medicare Tax', '0.9%'],
    ],
  },
  {
    title: `Additional Medicare ${currYear} Tax Rates`,
    rows: [
      ['Married filing jointly', '$250,000'],
      ['Married filing separate', '$125,000'],
      ['Single', '$200,000'],
      ['Head of household (with qualifying person)', '$200,000'],
      ['Qualifying widow(er) with dependent child', '$200,000'],
    ],
  },
  {
    title: `Education ${currYear} Credit and Deduction Limits`,
    rows: [
      ['American Opportunity Tax Credit', '$2,500'],
      ['Lifetime Learning Credit', '$2,000'],
      ['Student Loan Interest Deduction', '$2,500'],
      ['Coverdell Education Savings Contribution', '$2,000'],
    ],
  },
  {
    title: `Miscellaneous ${currYear} Tax Rates`,
    rows: [
      [
        'Standard deduction amount',
        '$14,600 for single filers, $29,200 for joint filers, or $21,900 for heads of household',
      ],
      ['Business Equipment Expense Deduction', '$1,220,000'],
      [
        'Prior-year safe harbor for estimated taxes of higher-income',
        `110% of your ${currYear - 1} tax liability`,
      ],
      ['Standard mileage rate for business driving', '67 Cents'],
      ['Standard mileage rate for medical/moving driving', '21 cents'],
      ['Standard mileage rate for charitable driving', '14 cents'],
      ['Child Tax Credit', '$2,000 per qualifying child'],
      [
        'Unearned income maximum for children before kiddie tax applies',
        '$1,250',
      ],
      [
        'Maximum capital gains tax rate for taxpayers in the 10% or 12% bracket',
        '0%',
      ],
      [
        'Maximum capital gains tax rate for taxpayers in the 22%, 24%, 32%, or > 35% bracket',
        '15%',
      ],
      [
        'Maximum capital gains tax rate for taxpayers in the 37% bracket',
        '20%',
      ],
      ['Capital gains tax rate for unrecaptured Sec. 1250 gains', '25%'],
      [
        'Capital gains tax rate on collectibles and qualified small business stock',
        '28%',
      ],
      [
        'Maximum contribution for Traditional/Roth IRA',
        '$7,000 if under age 50, $8,000 if 50 or older',
      ],
      [
        'Maximum employee contribution to SIMPLE IRA',
        '$16,000 if under age 50, $19,500 if 50 or older',
      ],
      ['Maximum Contribution to SEP IRA', '25% of compensation up to $69,000'],
      [
        '401(k) maximum employee contribution limit',
        '$23,000 if under age 50, $30,500 if 50 or older',
      ],
      ['Self-employed health insurance deduction', '100%'],
      ['Estate tax exemption', '$13,610,000'],
      ['Annual Exclusion for Gifts', '$17,000'],
      ['Foreign Earned Income Exclusion', '$126,500'],
    ],
  },
];