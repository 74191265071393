import React from 'react';
import ContentContainer from '../ContentContainer/ContentContainer';
import PFSContactCta from '../PFSContactCta/PFSContactCta';
import PFSServiceHeader from '../PFSServiceHeader/PFSServiceHeader';
import PFSServicePoints from '../PFSServicePoints/PFSServicePoints';
import PFSWhyChoosePFS from '../PFSWhyChoosePFS/PFSWhyChoosePFS';
import styles from './PFSWhatWeDoPayroll.module.css';
import globalStyles from '../../styles/global.module.css';

import PFSMeetTheTeam from '../PFSMeetTheTeam/PFSMeetTheTeam';
import payrollImage from './payroll.jpg'
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { WhatWeDoBreadcrumb } from '../PFSWhatWeDoIndex/PFSWhatWeDoIndex';
import { useLocation } from 'react-router-dom';

const PFSWhatWeDoPayroll = () => {
  const { pathname } = useLocation();

  useBreadcrumb([
    WhatWeDoBreadcrumb,
    {
      label: 'Payroll',
      url: pathname,
    },
  ]);
  return(
  <div className={styles.PFSWhatWeDoPayroll} data-testid='PFSWhatWeDoPayroll'>
    <ContentContainer>
      <PFSServiceHeader title='Payroll for the Independent Insurance Agency.' subtitle='Payroll' />
      <div className={globalStyles.IntroContainer}>
        <img alt='A calculator' src={payrollImage} className={globalStyles.IntroImage} />
        <div className={globalStyles.IntroText}>
          <p>At AgencyPoint, we provide independent insurance agencies with payroll processing solutions to simplify and expedite the payment process for any business. We offer a variety of services.</p>
          <p>By trusting us with your payroll needs, you can rest assured that your employees will be paid accurately and on time. If you are interested in streamlining your payroll process, contact us today to learn more about our services.</p>
        </div>
      </div>
      <PFSServicePoints
        title='Payroll Processing'
        points={[
          'Time and Attendance Software: Web, Mobile, Time Clock',
          'Direct Deposit and Paper Check Processing',
          'Federal and All 50 States Tax Payments and Filings',
          'Retirement, Health Insurance Plan, Supplemental Plan Reporting',
          "Pay as You Go Workers' Compensation Reporting",
          'W2/W3 Processing',
        ]}
      />
    </ContentContainer>
    <PFSWhyChoosePFS />
    <PFSMeetTheTeam department='Payroll' />
    <br />
    <br />
    <br />
    <br />
    <br />
    <PFSContactCta ctaText='Call us today!' />
  </div>
)};

PFSWhatWeDoPayroll.propTypes = {};

PFSWhatWeDoPayroll.defaultProps = {};

export default PFSWhatWeDoPayroll;
