import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './AccordionTitle.module.css';

const AccordionTitle = ({ title, Icon, iconFill }) => {
  const fillColor = useMemo(() => {
    let setColor = "#fff";
    if(iconFill && iconFill.isFilled) {
      setColor = iconFill.isFilledColor;
    } else {
      setColor = iconFill?.notFilledColor || setColor;
    }

    return setColor;
  }, [iconFill]);

  return (
    <div data-testid="AccordionTitle" className={styles.AccordionTitle}>
      <span className={styles.Image}>
        {Icon && <Icon fill={fillColor} />}
      </span>
      <span>{title}</span>
    </div>
  )
};

const iconFillShape = PropTypes.shape({
  isFilled: PropTypes.bool,
  isFilledColor: PropTypes.string,
  notFilledColor: PropTypes.string,
});

AccordionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  Icon: PropTypes.func,
  iconFill: iconFillShape.isRequired,
};

AccordionTitle.defaultProps = {
  title: undefined,
  Icon: undefined,
  iconFill: undefined,
};

export default AccordionTitle;
