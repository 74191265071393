import React, { useEffect } from 'react';
import styles from './PFSBody.module.css';

import { Route, Routes, useLocation } from 'react-router-dom';
import PFSHomepage from '../PFSHomepage/PFSHomepage';
import PFSContact from '../PFSContact/PFSContact';
import PFSCareers from '../PFSCareers/PFSCareers';
import PFS404Page from '../PFS404Page/PFS404Page';
import PFSWhatWeDoRoutes from '../PFSWhatWeDoRoutes/PFSWhatWeDoRoutes';
import PFSArticlesRoutes from '../PFSArticlesRoutes/PFSArticlesRoutes';
import PFSWhoWeAre from '../PFSWhoWeAre/PFSWhoWeAre';
import PFSPrivacyPolicy from '../PFSPrivacyPolicy/PFSPrivacyPolicy';
import ExternalRedirect from '../ExternalRedirect/ExternalRedirect';
import ScrollUp from '../ScrollUp/ScrollUp';
import PFSAcquisitionRoutes from '../PFSAcquisitionRoutes/PFSAcquisitionRoutes';
import PFSServicesRedirect from '../PFSServicesRedirect/PFSServicesRedirect';
import PFSPartnersRoutes from '../PFSPartnersRoutes/PFSPartnersRoutes';

export const payUrl = 'https://professionalfin.securepayments.cardpointe.com/';
export const legalPayUrl = 'https://pfslawapc.securepayments.cardpointe.com/';
export const officeUrl = 'https://pfsonline.quickbase.com/db/bi2uk6769';
export const uploadUrl =
  'https://pfsonline.sharefile.com/share/filedrop/dx9aa59c-d84a-4e84-b9e9-428b54abb87a';
export const quickAssistUrl = 'https://www.dualmon.com/assist/PC2';
export const scrollMargin = 150;

const externalLinks = [
  { path: '/pay', title: 'Pay Online', url: payUrl },
  { path: '/pay-online', title: 'Pay Online', url: payUrl },
  { path: '/lawpay', title: 'Pay Online', url: legalPayUrl },
  { path: '/office', title: 'Office Dashboard', url: officeUrl },
  { path: '/upload', title: 'Sensitive File Upload', url: uploadUrl },
  { path: '/remote', title: 'Quick Assist', url: quickAssistUrl },
  { path: '/quick-assist', title: 'Quick Assist', url: quickAssistUrl },
];

const offsetTopMinusMargin = (elem) => elem.offsetTop - scrollMargin;

function scrollAgainIfElemMoves(elem) {
  // let's make sure we re-adjust after scrolling
  setTimeout(() => {
    let elemPosition = offsetTopMinusMargin(elem); // re-eval since moved
    let scrollDiff = elemPosition - window.scrollY;
    let diffIsTooLarge = Math.abs(scrollDiff) > scrollMargin;

    if (diffIsTooLarge) {
      window.scrollBy({
        behavior: 'smooth',
        left: 0,
        top: scrollDiff,
      });
    }
  }, 1000);
}

function scrollTowards(elem) {
  if (!elem) {
    return;
  }

  window.scrollTo({
    top: offsetTopMinusMargin(elem),
    behavior: 'smooth',
  });

  scrollAgainIfElemMoves(elem);
}

const PFSBody = () => {
  const location = useLocation();

  useEffect(() => {
    if (!location.hash) {
      window.scroll(0, 0);
    } else {
      setTimeout(() => {
        scrollTowards(document.getElementById(location.hash));
      }, 200);
    }
  }, [location]);

  return (
    <div className={styles.PFSBody} data-testid='PFSBody'>
      <ScrollUp />
      <Routes>
        <Route path='/' element={<PFSHomepage />} />
        <Route path='/contact' element={<PFSContact />} />
        <Route path='/careers' element={<PFSCareers />} />
        <Route path='/privacy' element={<PFSPrivacyPolicy />} />
        <Route path='/services' element={<PFSServicesRedirect />} />
        <Route path='/what-we-do/*' element={<PFSWhatWeDoRoutes />} />
        <Route path='/articles/*' element={<PFSArticlesRoutes />} />
        <Route path='/who-we-are' element={<PFSWhoWeAre />} />
        <Route path='/acquisitions/*' element={<PFSAcquisitionRoutes />} />
        <Route path='/partners/*' element={<PFSPartnersRoutes />} />
        {externalLinks.map(({ path, title, url }) => (
          <Route
            key={path}
            path={path}
            element={<ExternalRedirect title={title} url={url} />}
          />
        ))}
        <Route path='*' element={<PFS404Page />} />
      </Routes>
    </div>
  );
};

export default PFSBody;
