import React, { useState } from 'react';
import { Alert, Button } from 'antd';
import styles from './PFSSubscribeBanner.module.css';
import { getHoursSinceCached } from '../../helpers/CacheHelpers';
import PFSSubscribeModal from '../PFSSubscribeModal/PFSSubscribeModal';

const getItemFromLocalStorage = (name) => {
  return localStorage ? localStorage.getItem(name) : null;
};

const PFSSubscribeBanner = () => {
  const lastIgnored = JSON.parse(getItemFromLocalStorage('lastIgnored'));
  const subscribed = JSON.parse(getItemFromLocalStorage('subscribe'));
  const ignoreHasExpired = getHoursSinceCached(lastIgnored) > 24;
  const [showBanner, setShowBanner] = useState(ignoreHasExpired && !subscribed);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  return (
    showBanner && (
      <>
        <div className={styles.BannerWrapper} data-testid="Subscribe-Banner">
          <Alert
            className={styles.Banner}
            onClose={() => {
              localStorage.setItem('lastIgnored', new Date().getTime());
              setShowBanner(false);
            }}
            description={
              <div data-testid="Banner-text">
                Subscribe to our mailing list!
                <Button
                  onClick={() => setShowSubscriptionModal(true)}
                  data-testid="Subscribe-Button"
                  className={styles.subscribeButton}
                >
                  Subscribe
                </Button>
              </div>
            }
            closable
          />
        </div>
        <PFSSubscribeModal
          open={showSubscriptionModal}
          setOpen={setShowSubscriptionModal}
          setShowBanner={setShowBanner}
        />
      </>
    )
  );
};
export default PFSSubscribeBanner;
