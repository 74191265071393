import React from 'react';
import globalStyles from '../../styles/global.module.css';
import styles from './PFSDueDates.module.css';
import ContentContainer from '../ContentContainer/ContentContainer';
import PFSVerticalTableComponent from '../PFSVerticalTableComponent/PFSVerticalTableComponent';
import PFSContactCta from '../PFSContactCta/PFSContactCta';
import data from './dueDatesData';

import { WhatWeDoTaxesBreadcrumb } from '../PFSWhatWeDoTaxes/PFSWhatWeDoTaxes';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { useLocation } from 'react-router-dom';

const PFSDueDates = () => {
  const { pathname } = useLocation();

  useBreadcrumb([
    ...WhatWeDoTaxesBreadcrumb,
    {
      label: 'Due Dates',
      url: pathname,
    },
  ]);

  return(
  <div className={styles.PfsDueDates} data-testid='PfsDueDates'>
    <ContentContainer>
      <center className={globalStyles.SubtitleText}>
        <b>Due Dates</b> <small>(Tax Calendar)</small>
        <p id={styles.SubtitleText}>
          All due dates assume that the date falls on a business day. <br />
          If the due date falls on a holiday or weekend, the due date will be
          the next business day.
        </p>
      </center>
      {data.map((d, index) => (
        <PFSVerticalTableComponent key={index} title={d.title} rows={d.rows} />
      ))}
    </ContentContainer>
    <br />
    <br />
    <br />
    <br />
    <br />
    <PFSContactCta ctaText='Call us today!' />
  </div>
)};

PFSDueDates.propTypes = {};

PFSDueDates.defaultProps = {};

export default PFSDueDates;
