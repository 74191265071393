import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faHandshake, faLightbulb, faShield, faGlobe } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import Slider from 'react-slick';

import styles from './PFSCareersCoreValues.module.css';

const PfsCareersCoreValues = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className={styles.PfsCareersCoreValues} data-testid="PfsCareersCoreValues">
      <center><h2>Our Values</h2></center>
      <Slider {...settings}>
        <div className={styles.CarouselItem}>
          <div className={styles.ValueIcon}>
            <FontAwesomeIcon icon={faShield} size='4x' />
          </div>
          <div className={styles.ValueText}>
            <h3>Integrity</h3>
            <p>We act with <strong>honesty</strong>, <strong>professionalism</strong>, and <strong>respect</strong> towards our colleagues and customers.</p>
            <p>
              We take responsibility for our actions, and we are committed to being trustworthy and reliable.
              Our team strives to build strong relationships with our clients based on mutual trust and respect,
              and we believe that integrity is the foundation of any successful partnership.
            </p>
          </div>
        </div>
        <div className={styles.CarouselItem}>
          <div className={styles.ValueIcon}>
            <FontAwesomeIcon icon={faLightbulb} size='4x' />
          </div>
          <div className={styles.ValueText}>
            <h3>Forward Thinking</h3>
            <p>We embrace <strong>creativity</strong>, <strong>innovation</strong>, and <strong>resourcefulness</strong> in everything we do.</p>
            <p>
              Our team is constantly looking for ways to improve our services, and we are inspired by new ideas and approaches.
              We believe that by being self-aware and open-minded, we can stay ahead of the curve and continue to grow and thrive.
            </p>
          </div>
        </div>
        <div className={styles.CarouselItem}>
          <div className={styles.ValueIcon}>
            <FontAwesomeIcon icon={faAward} size='4x' />
          </div>
          <div className={styles.ValueText}>
            <h3>Quality</h3>
            <p>Quality is our top priority. We are <strong>customer-focused</strong> and <strong>responsive</strong> to their needs.</p>
            <p>
              We strive to deliver results that exceed expectations. We are dedicated to providing polished & professional results.
              We believe that by providing our customers with the highest quality services possible, we can build long-lasting partnerships and drive success for all.
            </p>
          </div>
        </div>

        <div className={styles.CarouselItem}>
          <div className={styles.ValueIcon}>
            <FontAwesomeIcon icon={faHandshake} size='4x' />
          </div>
          <div className={styles.ValueText}>
            <h3>Commitment</h3>
            <p>We are a <strong>hardworking</strong>, <strong>ambitious</strong> team that is committed to delivering results.</p>
            <p>
              We are loyal to our customers and each other. We believe in providing opportunities based on merit, and we reward those who
              demonstrate dedication, talent, and a strong work ethic. We hold ourselves accountable and we believe that our commitment to excellence is what sets us apart.
            </p>
          </div>
        </div>
        <div className={styles.CarouselItem}>
          <div className={styles.ValueIcon}>
            <FontAwesomeIcon icon={faGlobe} size='4x' />
          </div>
          <div className={styles.ValueText}>
            <h3>Culture</h3>
            <p>Our company has a strong culture of <strong>teamwork</strong> and <strong>shared success</strong>.</p>
            <p>
              We value diversity and inclusivity and we strive to create an enjoyable, caring work environment where everyone feels supported and valued.
              We celebrate our successes together and learn from our mistakes, and we believe that by fostering a culture of open-mindedness and respect, we can
              achieve even greater things in the future.
            </p>
          </div>
        </div>

      </Slider>
    </div>
  );
};

export default PfsCareersCoreValues;