import React from 'react';
import PropTypes from 'prop-types'
import styles from './PFSIcon.module.css';

import iconSvg from './pfs_icon.svg';

const PFSIcon = (props) => (
  <div className={styles.PFSIcon} data-testid="PFSIcon">
    <img
      alt="PFS Icon"
      src={iconSvg}
      height={props.imgSize}
      width={props.imgSize}
    />
  </div>
);

PFSIcon.propTypes = {
  imgSize: PropTypes.number,
};

PFSIcon.defaultProps = {
  imgSize: 52,
};

export default PFSIcon;
