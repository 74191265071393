import React from 'react';
import PropTypes from 'prop-types';
import styles from './MobileNavButton.module.css';

const MobileNavButton = (props) => {
  let classes = `${styles.MobileNavButton} ${props.isOpen ? styles.IsOpen : ''}`;

  return (
    <div
      style={{
        position: props.isOpen ? 'fixed' : 'absolute',
        top: props.isOpen ? '2.8em' : '1em',
      }}
      onClick={props.updateNavToggle}
      className={classes}
      data-testid="MobileNavButton"
    >
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
};

MobileNavButton.propTypes = {
  updateNavToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

MobileNavButton.defaultProps = {
  updateNavToggle: undefined,
  isOpen: undefined,
};

export default MobileNavButton;
