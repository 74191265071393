import React from 'react';
import PropTypes from 'prop-types'
import styles from './PFSLogo.module.css';

import logoSvg from './pfs_logo.svg';

const PFSLogo = (props) => (
  <div className={styles.PFSLogo} data-testid="PFSLogo">
    <img alt="PFS Logo" src={logoSvg} width={props.imgSize} />
  </div>
);

PFSLogo.propTypes = {
  imgSize: PropTypes.number,
};

PFSLogo.defaultProps = {
  imgSize: 100,
};

export default PFSLogo;
