import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PFS404Page from '../PFS404Page/PFS404Page';
import PFSArticlesIndex from '../PFSArticlesIndex/PFSArticlesIndex';
import PFSArticleShow from '../PFSArticleShow/PFSArticleShow';

const PFSArticlesRoutes = () => (
  <Routes>
    <Route path='/' element={<PFSArticlesIndex />} />
    <Route path='/:slug' element={<PFSArticleShow />} />
    <Route path='*' element={<PFS404Page />} />
  </Routes>
);

PFSArticlesRoutes.propTypes = {};

PFSArticlesRoutes.defaultProps = {};

export default PFSArticlesRoutes;
