import React from 'react';
import PropTypes from 'prop-types';
import styles from './MobileNavMenu.module.css';
import NavMenuItems from '../NavMenuItems/NavMenuItems';
import PFSLogo from '../PFSLogo/PFSLogo';

const MobileNavMenu = (props) => {
  function toggleNav(e) {
    if(e.target === e.currentTarget) {
      props.updateNavToggle();
    }
  }

  let navClass = `${styles.MobileNavMenu} ${props.isOpen ? '' : styles.IsHidden}`

  return (
    <div onClick={toggleNav} className={navClass} data-testid="MobileNavMenu">
      <div id={styles.MobileNavContainer}>
        <div id={styles.LogoContainer}>
          <PFSLogo />
        </div>
        <NavMenuItems
          ListClass={styles.MobileNavMenuList}
          ListItemClass={styles.MobileNavItem}
          LinkActiveClass={styles.MobileActive}
          OnNavCallback={toggleNav}
        />
      </div>
    </div>
  )
};

MobileNavMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  updateNavToggle: PropTypes.func.isRequired
};

MobileNavMenu.defaultProps = {
  isOpen: undefined,
  updateNavToggle: undefined,
};

export default MobileNavMenu;
