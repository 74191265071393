import React from 'react';
import PropTypes from 'prop-types';
import styles from './ArticleLayout.module.css';
import SharePanel from '../SharePanel/SharePanel';
import AuthorPanel from '../AuthorPanel/AuthorPanel';

const ArticleLayout = (props) => {
  const { children, author } = props;
  return (
    <div className={styles.ArticleLayout} data-testid='ArticleLayout'>
      <div className={styles.content}>{children}</div>
      <div className={styles.SidePanel}>
        <SharePanel header='Share Article' />
        <AuthorPanel author={author ?? ''} />
      </div>
    </div>
  );
};

ArticleLayout.propTypes = {
  author: PropTypes.string.isRequired,
};

ArticleLayout.defaultProps = {
  author: undefined,
};

export default ArticleLayout;
