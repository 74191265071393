import * as React from 'react';
import PropTypes from 'prop-types';

const PFSWaveTop = (props) => {
  const { color } = props;
  return (
    <svg
      viewBox='0 0 324.426 61.021'
      xmlns='http://www.w3.org/2000/svg'
      style={{ display: 'block' }}
    >
      <path
        data-testid='wavetop'
        style={{
          display: 'inline',

          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        fill={color || '#153459'}
        d='m97.49 146.293-155.25-.001.003-44.497s17.33 12.345 65.081 12.345c47.751 0 101.178-8.476 155.664-19.524 54.486-11.049 103.675-9.233 103.675-9.233l.002 60.909z'
        transform='translate(57.76 -85.272)'
      />
    </svg>
  );
};

PFSWaveTop.propTypes = {
  color: PropTypes.string,
};

PFSWaveTop.defaultProps = {
  color: '#153459',
};

export default PFSWaveTop;
