import React from 'react';
import styles from './PFSFooterBranding.module.css';

import PFSIcon from '../PFSIcon/PFSIcon';
import ContentContainer from '../ContentContainer/ContentContainer';
import { Link } from 'react-router-dom';

const PFSFooterBranding = () => (
  <div className={styles.PFSFooterBranding} data-testid="PFSFooterBranding">
    <ContentContainer>
      <div className={styles.BrandingSection}>
        © { new Date().getFullYear() } PFS Global, LLC
      </div>
      <div className={styles.BrandingSection}>
        <Link to={'/'}>
          <PFSIcon imgSize={32} />
        </Link>
      </div>
      <div className={styles.BrandingSection}>
        <Link to={'/privacy'}>Privacy Policy</Link>
      </div>
    </ContentContainer>
  </div>
);

PFSFooterBranding.propTypes = {};

PFSFooterBranding.defaultProps = {};

export default PFSFooterBranding;
